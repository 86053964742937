import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { GlobalStyleProps } from "./../types/types";
import { CssBaseline } from "@material-ui/core";
import Footer from "../components/Footer";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
`;
const AuthContainer = styled.div`
  max-width: 520px;
  width:100%;
  min-height: calc(100vh - 85px);
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px 0;
  @media(max-width: 520px){
    min-height:80vh;
  }
`;

const Auth: React.FC = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AuthContainer>{children}</AuthContainer>

      <Footer />
    </Root>
  );
};

export default Auth;
