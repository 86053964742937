import PhoneNumber from "awesome-phonenumber";
import { BooleanObject } from "../types/types";
import geohash from "ngeohash";

export const checkFieldsEmpty = (emptyFields: BooleanObject) => {
  let countEmpty: number = 0;
  Object.keys(emptyFields).forEach((key) => {
    if (!emptyFields[key]) {
      countEmpty += 1;
    }
  });
  if (countEmpty > 0) {
    return false;
  } else {
    return true;
  }
};

export const stringToSlug = (string: string): string => {
  let str: string = string.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -.]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const nomalizePhoneNumber = (
  value: string,
  countryCode = "IT"
): string => {
  const phoneNumber = new PhoneNumber(value, countryCode);
  if (phoneNumber.isValid()) {
    return phoneNumber.getNumber().slice(1);
  }
  return value;
};

export const parseDate = (date: string | number, reverse:boolean = false): string => {
  const convertDate: Date = new Date(date);
  const YY = convertDate.getFullYear();
  const MM =
    convertDate.getMonth() + 1 < 10
      ? `0${convertDate.getMonth() + 1}`
      : convertDate.getMonth() + 1;
  const DD =
    convertDate.getDate() < 10
      ? `0${convertDate.getDate()}`
      : convertDate.getDate();
    return reverse?`${YY}-${MM}-${DD}`:`${DD}-${MM}-${YY}`;
};

export const getCoordinatePrecise = (lat: number, lng: number) => {
    const allHashes: Array<string> = [];
    for (let x = 2; x <= 8; x++) {
        allHashes.push(geohash.encode(lat, lng, x));
    }
    const hash = geohash.encode(lat, lng, 8);
    return {
        allHashes,
        hash,
        lat,
        lng,
    };
};
export  const getYesterday = () => {
    const date = new Date();

    const dd = date.getDate();
    const mm = date.getMonth()+1;
    const YY = date.getFullYear();

    return `${YY}-${mm}-${dd}`
};