import React from "react";
import {connect} from "react-redux";

import {Helmet} from 'react-helmet';

import DateFnsUtils from "@date-io/date-fns";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/core/styles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {StylesProvider} from "@material-ui/styles";
import {ThemeProvider} from "styled-components";

import maTheme from "./theme";
import Routes from "./routes/Routes";
import {AppStateType} from "./redux/reducers";
import {ThemeInitialStateType} from "./redux/reducers/themeReducers";
import * as firebase from "firebase/app";
import "firebase/performance";
import "firebase/analytics";
import {firebaseConfig, tagManagerArgs} from "../src/constants/config";
import {ProvideAuth} from "./helpers/useAuth";
import TagManager from 'react-gtm-module';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {IntlProvider} from 'react-intl'
import it from './lang/it.json';


if (tagManagerArgs && tagManagerArgs.gtmId && tagManagerArgs.gtmId.length) {
    TagManager.initialize(tagManagerArgs);
}

function App({theme}: { theme: ThemeInitialStateType }) {
    // eslint-disable-next-line
    
    firebase.initializeApp(firebaseConfig);
    firebase.performance();
    firebase.analytics();

    return (
        <React.Fragment>
            <Helmet
                titleTemplate="%s | Tips Here"
                defaultTitle="Tips Here"
            >
                <meta name="viewport" content= "width=device-width, user-scalable=no" />
                <link rel="apple-touch-startup-image" href="/static/img/logos/homescreen192.png"></link>
            </Helmet>
            <StylesProvider injectFirst>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
                        <ThemeProvider theme={maTheme[theme.currentTheme]}>
                            <IntlProvider messages={it} locale="it" defaultLocale="it">
                                <ProvideAuth>
                                    <Routes/>
                                </ProvideAuth>
                            </IntlProvider>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </React.Fragment>
    );
}

export default connect((store: AppStateType) => ({theme: store.themeReducer}))(App);
