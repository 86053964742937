const getAge18 = () => {
  const date = new Date();

  const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const mm =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const YY = date.getFullYear() - 18;

  return `${YY}-${mm}-${dd}`;
};
export default getAge18;

export const parseDateDatePicker = (date: any) => {
  const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const mm =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const YY = date.getFullYear();

  const parsedDate = `${YY}/${mm}/${dd}`;

  return parsedDate;
};
export const getAge18DatePicker = () => {
  const date = new Date();

  const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const mm =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const YY = date.getFullYear() - 18;

  return `${YY}/${mm}/${dd}`;
};
