import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalStyleProps, RouteInfoType } from "./../types/types";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  LinearProgress as MuiLinearProgress,
  withWidth,
} from "@material-ui/core";
import { isWidthUp } from "@material-ui/core/withWidth";
import useAuth from "../helpers/useAuth";
import useLogin from "../helpers/useLogin";
import AddToHome from "../components/AddToHome";

const drawerWidth = 260;
const LinearProgress = styled(MuiLinearProgress)(spacing);

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardPropsType = {
  routes: Array<RouteInfoType>;
  width: "md" | "xs" | "sm" | "lg" | "xl";
};

const Dashboard: React.FC<DashboardPropsType> = ({
  children,
  routes,
  width,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [renderPrivateContent, setRenderPrivateContent] = useState(true);
  const auth = useAuth();
  const login = useLogin();
  const history = useHistory();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!auth.token) {
      if (login.loadingLogin) {
        setRenderPrivateContent(false);
      } else {
        history.push(`/auth/sign-in?return=${location.pathname}`);
      }
    } else {
      if (auth.user && auth.user.role) {
        setRenderPrivateContent(!!auth.token);
      } else {
        setRenderPrivateContent(false);
      }
    }
    // eslint-disable-next-line
  }, [auth.token, login.loadingLogin, history, auth.user]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {renderPrivateContent ? (
        <>
          <Drawer>
            <Hidden mdUp implementation="js">
              <Sidebar
                routes={routes}
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden smDown implementation="css">
              <Sidebar
                routes={routes}
                PaperProps={{ style: { width: drawerWidth } }}
              />
            </Hidden>
          </Drawer>
          <AppContent>
            <Header onDrawerToggle={handleDrawerToggle} />
            <AddToHome />
            <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
              {children}
            </MainContent>
            <Footer />
          </AppContent>
        </>
      ) : (
        <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
          <LinearProgress my={2} />
        </MainContent>
      )}
    </Root>
  );
};

export default withWidth()(Dashboard);
