import { useMemo } from "react";
import useAuth from "./useAuth";

type PermissionsHook = {
    isSegnalatore: boolean;
    isAdmin: boolean;
    isRealEstate: boolean;
    hasPermission: (p: string) => boolean;
    environments: {[key: string]: string[]};
}

const usePermissions = (): PermissionsHook => {
    const auth = useAuth();
    
    return useMemo(() => {
        return { 
            isSegnalatore: auth.user ? auth.user.role === "SEGNALATORE" : false,
            isAdmin: auth.user ? auth.user.role === "ADMIN" : false,
            isRealEstate: auth.user ? auth.user.role === "AGENZIA" : false,
            hasPermission: (permission: string) => {
                const environment = auth.environment ?? 'system';
                const isAdmin = auth?.user?.role === "ADMIN";
                const permissions = auth?.user?.permissions;

                // an admin has full permissions on all environments besides System
                if (isAdmin) {
                    if (environment !== 'system') {
                        return true;
                    } else if (!permissions || Object.keys(permissions).length === 0 || typeof permissions['system'] === 'undefined' || (typeof permissions['system'] !== 'undefined' && permissions['system'].length === 0)) {
                        return true;
                    }
                } else if (auth?.user?.role === "SEGNALATORE") {
                    return false; // for SEGNALATORE we don't query permissions at all, anyone can do at least what a SEGNALATORE can do
                }

                if (!permissions || (typeof permissions[environment] === 'undefined' && environment !== 'system')) {
                    return false;
                }

                // invalid user permissions, don't know how to handle
                if (!Array.isArray(permissions[environment])) {
                    return false;
                }

                if (permissions[environment].length === 0) {
                    return true;
                }

                if (permissions[environment].includes(permission)) {
                    return true;
                }
                return false;
            },
            environments: auth.user && auth.user.permissions ? auth.user.permissions : {},
        }
    }, [auth])
}

export default usePermissions;