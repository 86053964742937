import * as React from "react";
import styled from "styled-components";

import {
  Grid,
  Hidden,
  List,
  ListItemText,
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps
} from "@material-ui/core";

interface ListItemProps extends MuiListItemProps {
  component?: string;
  href?: string;
  target?: string;
  button: boolean | undefined;
}

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(1) / 4}px
    ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)<ListItemProps>`
  display: inline-block;
  width: auto;
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={3}>
            <List>
              <ListItem button={true} component="a" href="https://tipshere.com/privacy.html" target="_blank">
                <ListItemText primary="Privacy" />
              </ListItem>
              <ListItem button={true} component="a" href="https://tipshere.com/terms.html" target="_blank">
                <ListItemText primary="Condizioni generali" />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={9} justify="flex-end">
          <List>
            <ListItem button={true} >
              <ListItemText primary={`© ${new Date().getFullYear()} - Tips Here srl | Sede Legale: Via San Vittore 39 - 20123 Milano (MI) | P.IVA IT11308970968 | Cap. Soc. € 10.000,00 | REA MI-2593967`} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
