import { useRef, useCallback, useEffect } from "react";
import "whatwg-fetch";
import {
  User,
  Segnalazione,
  ContattoType,
  BackendGetRequest,
  ImmobiliType,
  QuadranteType,
  Invite,
  Environment,
  AccountFatturazioneType,
  InvitesTypes,
  SubscriptionTypes,
} from "../types/types";
import useAuth from "./useAuth";
import { nomalizePhoneNumber } from "../helpers/utils";

const apiBase = process.env.REACT_APP_API_BASE;

const ROUTES = {
  whoAmI: "/whoAmI",
  myActiveInvites: "/myActiveInvites",
  myEnvironments: "/myEnvironments",
  updateMe: "/updateMe",
  users: "/users",
  segnalazioni: "/segnalazioni",
  contatti: "/contatti",
  immobile: "/immobili",
  quadranti: "/quadranti",
  accountFatturazione: "/account-fatturazione",
  accountFatturazioneUsers: "/account-fatturazione-users",
  accountFatturazioneInvite: "/account-fatturazione-invites",
  subscription: "/subscription",
};

type StringObject = {
  [key: string]: string | number;
};

type AuthSignature = {
  createUser: (user: User) => Promise<User>;
  setTokenHeader: (token: string) => void;
  whoAmI: (token?: string) => Promise<User | null>;
  me: {
    myActiveInvites: () => Promise<Invite[]>;
    myEnvironments: () => Promise<Environment[]>;
    updateMe: (user: User) => Promise<User>;
  };
  user: {
    get: (
      id?: string,
      filter?: BackendGetRequest
    ) => Promise<User | User[]>;
    count: (filter?: BackendGetRequest) => Promise<number>;
    disable: (id: string) => Promise<boolean>;
  },
  contatto: {
    search: (
      email?: string,
      phone?: string,
      firstName?: string,
      lastName?: string
    ) => Promise<ContattoType[]>;
    addNew: (contatto: ContattoType) => Promise<ContattoType>;
    update: (contatto: ContattoType) => Promise<ContattoType>;
    get: (
      id?: string,
      filter?: BackendGetRequest
    ) => Promise<ContattoType | ContattoType[]>;
    count: () => Promise<number>;
  };
  immobile: {
    addNew: (immobili: ImmobiliType) => Promise<ImmobiliType>;
    update: (immobili: ImmobiliType) => Promise<ImmobiliType>;
    get: (
      id?: string,
      filter?: BackendGetRequest
    ) => Promise<ImmobiliType | ImmobiliType[]>;
    count: () => Promise<number>;
    delete: (id: string) => Promise<void>;
  };
  quadranti: {
    get: (
      id?: string,
      filter?: BackendGetRequest
    ) => Promise<QuadranteType | QuadranteType[]>;
    count: () => Promise<number>;
    addNew: (quadrante: QuadranteType) => Promise<QuadranteType>;
    update: (quadrante: QuadranteType) => Promise<QuadranteType>;
  };
  segnalazione: {
    create: (segnalazione: Segnalazione) => Promise<Segnalazione>;
    update: (segnalazione: Segnalazione) => Promise<Segnalazione>;
    get: (
      filter?: string | BackendGetRequest
    ) => Promise<Segnalazione | Segnalazione[]>;
    count: (filter?: BackendGetRequest) => Promise<number>;
  };
  invite: {
    update: (invite: Invite) => Promise<Invite>;
  };
  accountFatturazione: {
    get: (
      id?: string,
      filter?: BackendGetRequest
    ) => Promise<AccountFatturazioneType | AccountFatturazioneType[]>;
    addNew: (
      accountFatturazione: AccountFatturazioneType
    ) => Promise<AccountFatturazioneType>;
    update: (
      accountFatturazione: AccountFatturazioneType
    ) => Promise<AccountFatturazioneType>;
    count: () => Promise<number>;
    delete: (id: string) => Promise<void>;
    users: (filter?: BackendGetRequest) => Promise<{accountFatturazioneId: string, userId: string}[]>
  };
  accountFatturazioneInvite: {
    get: (
      id?: string,
      filter?: BackendGetRequest
    ) => Promise<InvitesTypes | InvitesTypes[]>;
    addNew: (invite: InvitesTypes) => Promise<InvitesTypes>;
  };
  subscription: {
    get: (
      id?: string,
      filter?: BackendGetRequest
    ) => Promise<SubscriptionTypes | SubscriptionTypes[]>;
    count: () => Promise<number>;
    addNew: (subscription: SubscriptionTypes) => Promise<SubscriptionTypes>;
    update: (subscription: SubscriptionTypes) => Promise<SubscriptionTypes>;
    delete: (id: string) => Promise<void>;
  };
};

const useApi = (): AuthSignature => {
  const { token, environment } = useAuth();
  const tokenHeaderRef = useRef<string | null>(null);

  useEffect(() => {
    setTokenHeader(`${token}`);
  }, [token]);

  const apiHeaders = useCallback(() => {
    return {
      "Content-Type": "application/json",
      ...(tokenHeaderRef.current && {
        "X-Firebase-Access-Token": `${tokenHeaderRef.current}`,
      }),
      ...(environment && {
        "X-Current-Environment": `${environment}`,
      }),
    };
  }, [tokenHeaderRef, environment]);
  const setTokenHeader = (token: string) => {
    tokenHeaderRef.current = token;
  };

  const getRequest = async (
    route: string,
    queryParams?: StringObject | null
  ): Promise<any> => {
    let url = `${apiBase}${route}`;
    if (queryParams && Object.keys(queryParams).length) {
      url += `?${Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&")}`;
    }
    const result = await fetch(url, {
      method: "GET",
      redirect: "follow",
      headers: apiHeaders(),
    });
    const body = await result.json();
    if (!result.ok) {
      throw new Error(body);
    }
    return body;
  };

  const sendRequest = async (
    route: string,
    sendData: any,
    method: string = "POST"
  ): Promise<any> => {
    let url = `${apiBase}${route}`;
    const result = await fetch(url, {
      method,
      redirect: "follow",
      headers: apiHeaders(),
      body: sendData ? JSON.stringify(sendData) : null,
    });

    if (!result.ok) {
      try {
        const body = await result.json();
        throw body;
      } catch (err) {
        const error = {
          data: {
            message:
              err.error.message ||
              `Unknown error with status code ${result.status}`,
          },
        };
        throw error;
      }
    }
    if (result.status !== 204) {
      return await result.json();
    }
  };

  const whoAmI = async (token?: string): Promise<User | null> => {
    if (token) {
      setTokenHeader(token);
    }

    try {
      return await getRequest(ROUTES.whoAmI);
    } catch (err) {
      return null;
    }
  };

  const getUsers = async (
    id?: string,
    filter?: BackendGetRequest
  ) => {
    try {
      const query = filter ? { filter: JSON.stringify(filter) } : undefined;
      if (id) {
        return await getRequest(`${ROUTES.users}/${id}`, query);
      }
      return await getRequest(`${ROUTES.users}`, query);
    } catch (err) {
      console.error("Error during retrieving users", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const countUsers = async () => {
    try {
      const count = await getRequest(
        `${ROUTES.users}/count`
      );
      return count.count;
    } catch (err) {
      console.error("Error during counting users", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const disableUser = async (id: string): Promise<boolean> => {
    try {
      await sendRequest(`${ROUTES.users}/${id}/disable`, {}, "PUT");
      return true;
    } catch (err) {
      console.error("Error disabling user", err, id);
      return false
    }
  }

  const myActiveInvites = async (): Promise<Invite[]> => {
    try {
      return await getRequest(ROUTES.myActiveInvites);
    } catch (err) {
      return [];
    }
  };

  const myEnvironments = async (): Promise<Environment[]> => {
    try {
      return await getRequest(ROUTES.myEnvironments);
    } catch (err) {
      return [];
    }
  };

  const createUser = async (user: User): Promise<User> => {
    try {
      return await sendRequest(ROUTES.users, user, "POST");
    } catch (err) {
      console.error("Error creating user", err, user);
      throw new Error(err.error.message || "Unknown error");
    }
  };

  const updateMe = async (user: User): Promise<User> => {
    try {
      const { authuid, ...sendData } = user;
      return await sendRequest(ROUTES.updateMe, sendData, "PUT");
    } catch (err) {
      console.error("Error updating user", err, user);
      throw new Error(err.error.message || "Unknown error");
    }
  };

  const getSegnalazioni = async (filter?: string | BackendGetRequest) => {
    try {
      return await getRequest(
        `${ROUTES.segnalazioni}/${typeof filter === "string" ? filter : ""}`,
        filter && typeof filter !== "string"
          ? {
              filter: JSON.stringify(
                Object.assign(filter, { order: "data_inserimento DESC" })
              ),
            }
          : { filter: JSON.stringify({ order: "data_inserimento DESC" }) }
      );
    } catch (err) {
      console.error("Error during retrieving segnalazioni", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const countSegnalazioni = async (filter?: BackendGetRequest) => {
    try {
      const countSegnalazioni = await getRequest(
        `${ROUTES.segnalazioni}/count`,
        filter?.where ? {where: JSON.stringify(filter.where)} : null
      );
      return countSegnalazioni.count;
    } catch (err) {
      console.error("Error during retrieving segnalazioni", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const createSegnalazione = async (segnalazione: Segnalazione) => {
    try {
      return await sendRequest(ROUTES.segnalazioni, segnalazione, "POST");
    } catch (err) {
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const updateSegnalazione = async (segnalazione: Segnalazione) => {
    try {
      let { id, ...data } = segnalazione;
      return await sendRequest(`${ROUTES.segnalazioni}/${id}`, data, "PUT");
    } catch (err) {
      console.error("Error during updating segnalazione", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const searchContatti = async (
    email?: string,
    phone?: string,
    firstName?: string,
    lastName?: string
  ) => {
    const andConditions = [];
    if (email && email.length && email.includes("@")) {
      andConditions.push({ email: email.toLocaleLowerCase() });
    }

    if (phone && phone.length >= 9) {
      andConditions.push({
        telefono_cellulare: { inq: [nomalizePhoneNumber(phone)] },
      });
      // andConditions.push({"or": {
      //   "telefono_fisso": nomalizePhoneNumber(phone),
      //   "telefono_cellulare": nomalizePhoneNumber(phone)
      // }});
    }

    if (firstName && firstName.length) {
      andConditions.push({ nome: firstName });
    }

    if (lastName && lastName.length) {
      andConditions.push({ cognome: lastName });
    }

    const getQuery = {
      filter: JSON.stringify({
        offset: 0,
        limit: 20,
        skip: 0,
        where: {
          and: andConditions,
        },
      }),
    };

    try {
      return await getRequest(`${ROUTES.contatti}/`, getQuery);
    } catch (err) {
      console.error("Error during retrieving contatti", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const addNewContatto = async (contatti: ContattoType) => {
    try {
      return await sendRequest(ROUTES.contatti, contatti, "POST");
    } catch (err) {
      console.error("Error during retrieving contatti", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const updateContatti = async (contatti: ContattoType) => {
    try {
      let { id, ...data } = contatti;
      return await sendRequest(`${ROUTES.contatti}/${id}`, data, "PUT");
    } catch (err) {
      console.error("Error during updating contatti", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const addNewImmobile = async (immobile: ImmobiliType) => {
    try {
      return await sendRequest(ROUTES.immobile, immobile, "POST");
    } catch (err) {
      console.error("Error during retrieving immobili", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const updateImmobile = async (immobile: ImmobiliType) => {
    try {
      let { id, ...data } = immobile;
      return await sendRequest(`${ROUTES.immobile}/${id}`, data, "PUT");
    } catch (err) {
      console.error("Error during updating immobili", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const getImmobile = async (id?: string, filter?: BackendGetRequest) => {
    try {
      if (id) {
        return await getRequest(
          `${ROUTES.immobile}/${id}`,
          filter ? { filter: JSON.stringify(filter) } : undefined
        );
      }
      return await getRequest(
        `${ROUTES.immobile}`,
        filter ? { filter: JSON.stringify(filter) } : undefined
      );
    } catch (err) {
      console.error("Error during retrieving immobile", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const countImmobili = async () => {
    try {
      const countImmobili = await getRequest(`${ROUTES.immobile}/count`);
      return countImmobili.count;
    } catch (err) {
      console.error("Error during retrieving segnalazioni", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const getAccountFatturazione = async (
    id?: string,
    filter?: BackendGetRequest
  ) => {
    try {
      const query = filter ? { filter: JSON.stringify(filter) } : undefined;
      if (id) {
        return await getRequest(`${ROUTES.accountFatturazione}/${id}`, query);
      }
      return await getRequest(`${ROUTES.accountFatturazione}`, query);
    } catch (err) {
      console.error("Error during retrieving account di fatturazione", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const getAccountFatturazioneUsers = async (
    filter?: BackendGetRequest
  ) => {
    try {
      const query = filter ? { filter: JSON.stringify(filter) } : undefined;
      return await getRequest(`${ROUTES.accountFatturazioneUsers}`, query);
    } catch (err) {
      console.error("Error during retrieving account di fatturazione users", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const addNewAccountFatturazione = async (
    accountFatturazione: AccountFatturazioneType
  ) => {
    try {
      return await sendRequest(
        ROUTES.accountFatturazione,
        accountFatturazione,
        "POST"
      );
    } catch (err) {
      console.error("Error during retrieving immobili", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const updateAccountFatturazione = async (
    accountFatturazione: AccountFatturazioneType
  ) => {
    try {
      let { id, ...data } = accountFatturazione;
      return await sendRequest(
        `${ROUTES.accountFatturazione}/${id}`,
        data,
        "PUT"
      );
    } catch (err) {
      console.error("Error during updating immobili", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const countAccountFatturazione = async () => {
    try {
      const countImmobili = await getRequest(
        `${ROUTES.accountFatturazione}/count`
      );
      return countImmobili.count;
    } catch (err) {
      console.error("Error during retrieving segnalazioni", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const deleteAccountFatturazione = async (id: string) => {
    try {
      await sendRequest(
        `${ROUTES.accountFatturazione}/${id}`,
        undefined,
        "DELETE"
      );
    } catch (err) {
      console.log("Error during deleting proprietari-immobili", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const getContatti = async (id?: string, filter?: BackendGetRequest) => {
    try {
      const query = filter ? { filter: JSON.stringify(filter) } : undefined;
      if (id) {
        return await getRequest(`${ROUTES.contatti}/${id}`, query);
      }
      return await getRequest(`${ROUTES.contatti}`, query);
    } catch (err) {
      console.error("Error during retrieving contatto", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const countContatti = async () => {
    try {
      const countImmobili = await getRequest(`${ROUTES.contatti}/count`);
      return countImmobili.count;
    } catch (err) {
      console.error("Error during retrieving segnalazioni", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const addNewQuadrante = async (quadrante: QuadranteType) => {
    try {
      return await sendRequest(ROUTES.quadranti, quadrante, "POST");
    } catch (err) {
      console.error("Error during retrieving contatti", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };
  const updateQuadrante = async (quadrante: QuadranteType) => {
    try {
      let { id, ...data } = quadrante;
      return await sendRequest(`${ROUTES.quadranti}/${id}`, data, "PUT");
    } catch (err) {
      console.error("Error during updating contatti", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const countQuadranti = async () => {
    try {
      const countQuadranti = await getRequest(`${ROUTES.quadranti}/count`);
      return countQuadranti.count;
    } catch (err) {
      console.error("Error during retrieving segnalazioni", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const getQuadranti = async (id?: string, filter?: BackendGetRequest) => {
    try {
      const query = filter ? { filter: JSON.stringify(filter) } : undefined;
      if (id) {
        return await getRequest(`${ROUTES.quadranti}/${id}`, query);
      }
      return await getRequest(`${ROUTES.quadranti}`, query);
    } catch (err) {
      console.error("Error during retrieving quadranti", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const deleteImmobili = async (immobileId: string) => {
    try {
      await sendRequest(
        `${ROUTES.immobile}/${immobileId}`,
        undefined,
        "DELETE"
      );
    } catch (err) {
      console.log("Error during deleting proprietari-immobili", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const updateInvite = async (invite: Invite) => {
    try {
      let { id, ...data } = invite;
      return await sendRequest(
        `${ROUTES.accountFatturazioneInvite}/${id}`,
        data,
        "PUT"
      );
    } catch (err) {
      console.error("Error during updating invite", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };
  const getAccountFatturazioneInvites = async (
    id?: string,
    filter?: BackendGetRequest
  ) => {
    try {
      const query = filter ? { filter: JSON.stringify(filter) } : undefined;
      if (id) {
        // return await getRequest(`${ROUTES.accountFatturazioneInvite}/${id}`,query);
      }
      return await getRequest(`${ROUTES.accountFatturazioneInvite}`, query);
    } catch (err) {
      console.error("Error during retrieving invites", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const addNewAccountFatturazioneInvites = async (invites: InvitesTypes) => {
    try {
      return await sendRequest(
        ROUTES.accountFatturazioneInvite,
        invites,
        "POST"
      );
    } catch (err) {
      console.error("Error during add invites", err);
      throw new Error(err?.data?.message || "Unknown error");
    }
  };

  const getSubscription = async (id?: string, filter?: BackendGetRequest) => {
    try {
      const query = filter ? { filter: JSON.stringify(filter) } : undefined;
      if (id) {
        return await getRequest(`${ROUTES.subscription}/${id}`, query);
      }
      return await getRequest(`${ROUTES.subscription}`, query);
    } catch (err) {
      console.error("Error during add invites", err);
      throw new Error(err.data.message || "Unknown error");
    }
  };

  const countSubscription = async () => {
    try {
      const countSubscription = await getRequest(
        `${ROUTES.subscription}/count`
      );
      return countSubscription.count;
    } catch (err) {
      console.error("Error during retrieving segnalazioni", err);
      throw new Error(err.data.message || "Unknown error");
    }
  };

  const addNewSubscription = async (subscription: SubscriptionTypes) => {
    try {
      return await sendRequest(ROUTES.subscription, subscription, "POST");
    } catch (err) {
      console.error("Error during retrieving immobili", err);
      throw new Error(err.data.message || "Unknown error");
    }
  };

  const updateSubscription = async (subscription: SubscriptionTypes) => {
    try {
      let { id, ...data } = subscription;
      return await sendRequest(`${ROUTES.subscription}/${id}`, data, "PUT");
    } catch (err) {
      console.error("Error during updating immobili", err);
      throw new Error(err.data.message || "Unknown error");
    }
  };

  const deleteSubscription = async (id: string) => {
    try {
      await sendRequest(`${ROUTES.subscription}/${id}`, undefined, "DELETE");
    } catch (err) {
      console.log("Error during deleting proprietari-immobili", err);
      throw new Error(err.data.message || "Unknown error");
    }
  };

  return {
    setTokenHeader,
    whoAmI,
    me: {
      myActiveInvites,
      updateMe,
      myEnvironments,
    },
    user: {
      get: getUsers,
      count: countUsers,
      disable: disableUser,
    },
    createUser,
    contatto: {
      search: searchContatti,
      addNew: addNewContatto,
      update: updateContatti,
      get: getContatti,
      count: countContatti,
    },
    immobile: {
      addNew: addNewImmobile,
      update: updateImmobile,
      get: getImmobile,
      count: countImmobili,
      delete: deleteImmobili,
    },
    quadranti: {
      get: getQuadranti,
      count: countQuadranti,
      addNew: addNewQuadrante,
      update: updateQuadrante,
    },
    segnalazione: {
      create: createSegnalazione,
      update: updateSegnalazione,
      get: getSegnalazioni,
      count: countSegnalazioni,
    },
    invite: {
      update: updateInvite,
    },
    accountFatturazione: {
      get: getAccountFatturazione,
      addNew: addNewAccountFatturazione,
      update: updateAccountFatturazione,
      count: countAccountFatturazione,
      delete: deleteAccountFatturazione,
      users: getAccountFatturazioneUsers,
    },
    accountFatturazioneInvite: {
      get: getAccountFatturazioneInvites,
      addNew: addNewAccountFatturazioneInvites,
    },
    subscription: {
      get: getSubscription,
      count: countSubscription,
      addNew: addNewSubscription,
      update: updateSubscription,
      delete: deleteSubscription,
    },
  };
};

export default useApi;
