import { useState, useEffect } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import useAuth from "./useAuth";
import useApi from "./useApi";

type LoginHook = {
  loadingLogin: boolean;
  facebookLogin: () => Promise<boolean>;
  googleLogin: () => Promise<boolean>;
  createUser: (
    email: string,
    password: string,
    nome: string,
    cognome: string,
    date: Date
  ) => Promise<boolean>;
  signInUser: (email: string, password: string) => Promise<boolean>;
  signOut: () => Promise<boolean>;
  passwordReset: (email: string) => Promise<boolean>;
  newPasswordSet: (code: string, newPassword: string) => Promise<boolean>;
  changePassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<boolean>;
};

const useLogin = (): LoginHook => {
  const {
    user,
    setUser,
    token,
    setToken,
    isLogged,
    setIsLogged,
    setEnvironment,
  } = useAuth();
  const [loginFirebaseCompleted, setLoginFirebaseCompleted] = useState<boolean>(
    isLogged
  );
  const [loadingLogin, setLoadingLogin] = useState<boolean>(true);
  const [signupWithEmailFlow, setSignupWithEmailFlow] = useState<boolean>(
    false
  );
  const { whoAmI, setTokenHeader, createUser: apiCreateUser } = useApi();

  const loadUser = async () => {
    if (token) {
      const firebaseUser = firebase.auth().currentUser;
      const user = await whoAmI(token);
      setLoadingLogin(false);
      if (user) {
        if (firebaseUser && firebaseUser.providerData.length > 0) {
          setUser({
            ...user,
            providerId: firebaseUser.providerData[0]?.providerId || null,
          });
        } else {
          setUser(user);
        }
      } else {
        setToken(null);
      }
    }
  };

  useEffect(() => {
    if (token) {
      if (!user) {
        setLoginFirebaseCompleted(true);
        setTokenHeader(token);
      }
    }
    setLoadingLogin(false);
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    firebase.auth().languageCode = "it";
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    setLoadingLogin(true);
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user?.getIdToken();
      if (token && !signupWithEmailFlow) {
        setToken(token);
      }
    });
    setTimeout(() => {
      if (!token) {
        setLoadingLogin(false);
      }
    }, 3000);

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loginFirebaseCompleted && !user && !signupWithEmailFlow) {
      setTimeout(() => loadUser(), 1000);
    }
    // eslint-disable-next-line
  }, [loginFirebaseCompleted, signupWithEmailFlow]);

  const googleLogin = async (): Promise<boolean> => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    firebase.auth().useDeviceLanguage();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const token = await result?.user?.getIdToken();
      if (token) {
        setToken(token);
      }
    } catch (error) {
      return false;
    }

    return true;
  };

  const facebookLogin = async (): Promise<boolean> => {
    const provider = new firebase.auth.FacebookAuthProvider();
    // provider.addScope("user_birthday");
    firebase.auth().useDeviceLanguage();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const token = await result?.user?.getIdToken();
      if (token) {
        setToken(token);
      }
    } catch (error) {
      return false;
    }

    return true;
  };

  const signInUser = async (
    email: string,
    password: string
  ): Promise<boolean> => {
    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const token = await result?.user?.getIdToken();
      if (token) {
        setToken(token);
      }
    } catch (error) {
      throw error;
    }

    return true;
  };

  const createUser = async (
    email: string,
    password: string,
    nome: string,
    cognome: string,
    date: Date
  ): Promise<boolean> => {
    try {
      setSignupWithEmailFlow(true);
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      const token = await result?.user?.getIdToken();
      const authuid = result?.user?.uid;
      if (authuid && nome && cognome) {
        await apiCreateUser({
          nome,
          cognome,
          data_di_nascita: date.toISOString(),
          email,
          authuid,
        });
        setSignupWithEmailFlow(false);
      }
      if (token) {
        setToken(token);
      }
    } catch (err) {
      console.error("Signup error", err);
      throw err;
    }
    return true;
  };

  const signOut = async (): Promise<boolean> => {
    if (!isLogged) {
      return false;
    }
    try {
      await firebase.auth()?.signOut();
      setToken(null);
      setIsLogged(false);
      setUser(null);
      setEnvironment("system");
    } catch (err) {
      // TODO what we should do here?
      return false;
    }
    return true;
  };

  const passwordReset = async (email: string): Promise<boolean> => {
    if (!email) {
      return false;
    }

    try {
      await firebase.auth()?.sendPasswordResetEmail(email, {
        url: `${window.location.href}?emailSent=true`,
      });
      setToken(null);
      setIsLogged(false);
      setUser(null);
    } catch (err) {
      // TODO what we should do here?
      return false;
    }
    return true;
  };

  const newPasswordSet = async (
    code: string,
    password: string
  ): Promise<boolean> => {
    if (!code || !password) {
      return false;
    }

    try {
      await firebase.auth()?.confirmPasswordReset(code, password);
      setToken(null);
      setIsLogged(false);
      setUser(null);
    } catch (err) {
      // TODO what we should do here?
      return false;
    }
    return true;
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user!.email!,
          oldPassword
        );
        const userReAuthenticate = await user.reauthenticateAndRetrieveDataWithCredential(
          credential
        );
        if (userReAuthenticate.user) {
          await userReAuthenticate.user.updatePassword(newPassword);
        }
      }
    } catch (error) {
      throw error;
    }

    return true;
  };

  return {
    facebookLogin,
    googleLogin,
    createUser,
    signInUser,
    signOut,
    passwordReset,
    newPasswordSet,
    loadingLogin,
    changePassword,
  };
};

export default useLogin;
