import React, { useState, useEffect, useContext, createContext, useRef } from "react";
import {User} from '../types/types';
import useStorage from './useStorage';

type AuthHook = {
    user: User | null, 
    setUser: (user: User | null) => void,
    isLogged: boolean,
    setIsLogged: (status: boolean) => void,
    token: string | null,
    setToken: (token: string | null) => void,
    environment: string,
    setEnvironment: (environment: string) => void,
}

const AuthContext = createContext<AuthHook>({
    user: null, 
    isLogged: false,
    token: null,
    environment: 'system',
    setUser: () => undefined,
    setIsLogged: () => undefined,
    setToken: () => undefined,
    setEnvironment: () => undefined,
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
        {children}
    </AuthContext.Provider>
    );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};



const useProvideAuth = () => {
    const isLoggedRef = useRef<boolean>(false);
    const [token, setToken] = useState<string | null>(null);
    const [environment, setEnvironment] = useStorage('current-environment', 'system');
    const [user, setUser] = useState<User | null>(null);

    const setIsLogged = (status: boolean) => {
        isLoggedRef.current = status;
    }

    useEffect(() => {
        if(token) {
            setIsLogged(true);
        }
        // eslint-disable-next-line
    }, [token]);

    return {
        user, setUser, 
        token, setToken,
        environment, setEnvironment,
        isLogged: isLoggedRef.current, setIsLogged
    }
}

export default useAuth;