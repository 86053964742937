import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
} from "react-router-dom";
import {
  dashboardLayoutRoutesAdmin,
  dashboardLayoutRoutesUser,
  dashboardLayoutRoutesRealEstate,
  authLayoutRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import {
  Paper as MuiPaper,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import { RouteInfoType } from "../types/types";
import usePermissions from "../helpers/usePermissions";
import useLogin from "../helpers/useLogin";
import useAuth from "../helpers/useAuth";
import useMobile from "../helpers/useMobile";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  routes.map(({ component: Component, children, path }, index: number) => {
    return children ? (
      children.map((element, index: number) => (
        <Route
          key={index}
          path={element.path}
          exact
          render={(props: RouteComponentProps) => (
            <Layout>
              <element.component {...props} />
            </Layout>
          )}
        />
      ))
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : null;
  });

const loading = () => (
  <MuiPaper>
    <MuiLinearProgress />
  </MuiPaper>
);

const Routes = () => {
  const permissions = usePermissions();
  const login = useLogin();
  const auth = useAuth();
  const [dashboardLayoutRoutes, setDashboardLayoutRoutes] = useState<
    Array<RouteInfoType>
  >(dashboardLayoutRoutesUser);
  const { isMobileApp } = useMobile();
  useEffect(() => {
    if (permissions.isAdmin && !isMobileApp) {
      setDashboardLayoutRoutes(dashboardLayoutRoutesAdmin);
    } else if (permissions.isRealEstate && !isMobileApp) {
      setDashboardLayoutRoutes(dashboardLayoutRoutesRealEstate);
    }
  }, [login.loadingLogin, permissions, isMobileApp]);
  return (
    <Router>
      <Switch>
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        <Route
          render={() => {
            if (login.loadingLogin) {
              return loading();
            } else if (auth.token && !auth.user) {
              return loading();
            }

            return (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
