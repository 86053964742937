import React, { useState } from "react";
import AddToHomescreen from "react-add-to-homescreen";
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@material-ui/core";
import styled from "styled-components";
import useStorage from "../helpers/useStorage";

const Dialog = styled(MuiDialog)`
  .MuiDialog-paper {
    width: 100%;
  }
  .dialog-title {
    text-align: center;
  }
`;
const Container = styled.div`
  z-index: 10;
  .add-to-home-icon {
    padding: 2px;
  }
  .add-to-home-dialog {
    width: 100%;
  }
  .add-to-home-banner {
    background: #fa6635;
  }
`;
const AddToHome: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [seen, setSeen] = useStorage('add-to-home', false);

  return seen ? null : (
    <Container>
      <AddToHomescreen
        onAddToHomescreenClick={() => {
          setIsModalOpen(true);
        }}
        title={"Aggiungimi alla tua Home"}
        icon={"/favicon.png"}
      />
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="addToHome"
        maxWidth={"lg"}
      >
        <DialogTitle id="addToHome" className={"dialog-title"}>
          {"Aggiungimi alla tua Home"}
        </DialogTitle>

        <DialogContent>
          <p>
            <strong>Step1:</strong> Apri il menu di condivisione
          </p>
          <p>
            <strong>Step2:</strong> Premi il bottone "Aggiungi alla Home Screen"
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              setIsModalOpen(false);
              setSeen(true);
            }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AddToHome;
