import { IntlFormatters } from "react-intl";
import { FormFields, StringObject, DropZoneAreaProps } from "../types/types";
import getAge18 from "../helpers/age";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_SENDERID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APPID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENTID}`,
};
export const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GTM}`,
};

export const algolia = {
  appId: `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
  apiKey: `${process.env.REACT_APP_ALGOLIA_API_KEY}`,
};

export const formFieldsSignUp = (intl: IntlFormatters): Array<FormFields> => [
  {
    id: "nome",
    name: "nome",
    label: intl.formatMessage({
      id: "SIGNUP_FNAME",
      defaultMessage: "Nome",
      description: `SignUp field Nome (nome)`,
    }),
    required: true,
    initialValue: "",
    type: "text",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    InputLabelProps: {
      shrink: true,
    },
  },
  {
    id: "cognome",
    name: "cognome",
    label: intl.formatMessage({
      id: "SIGNUP_LNAME",
      defaultMessage: "Cognome",
      description: `SignUp field Cognome (cognome)`,
    }),
    required: true,
    initialValue: "",
    type: "text",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    InputLabelProps: {
      shrink: true,
    },
  },
  {
    id: "email",
    name: "email",
    label: intl.formatMessage({
      id: "SIGNUP_EMAIL",
      defaultMessage: "Email",
      description: `SignUp field Email (email)`,
    }),
    required: true,
    autoComplete: "email",
    type: "text",
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: intl.formatMessage({
          id: "GENERAL_EMAIL_INVALID",
          defaultMessage: "Indirizzo email non valido",
          description: `Form field invalid email message`,
        }),
      },
    },
    InputLabelProps: {
      shrink: true,
    },
  },
  {
    id: "password",
    name: "password",
    label: intl.formatMessage({
      id: "SIGNUP_PASSWORD",
      defaultMessage: "Password",
      description: `SignUp field Password (password)`,
    }),
    required: true,
    type: "password",
    autoComplete: "current-password",
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
      pattern: {
        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
        message: intl.formatMessage({
          id: "SIGNUP_PASSWORD_VALIDATION",
          defaultMessage:
            "La password deve essere compresa tra 6 e 20 con almeno una cifra numerica, una lettera maiuscola e una minuscola",
          description: `Form field invalid email message`,
        }),
      },
    },
    InputLabelProps: {
      shrink: true,
    },
  },
  {
    id: "date",
    name: "date",
    label: intl.formatMessage({
      id: "SIGNUP_BIRTH_DATE",
      defaultMessage: "Data di nascita",
      description: `SignUp field Data di nascita (date)`,
    }),
    required: true,
    type: "date",
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      max: `${getAge18()}`,
    },
  },
  {
    id: "privacy",
    name: "privacy",
    label: intl.formatMessage({
      id: "SIGNUP_PRIVACY",
      defaultMessage:
        "Confermo di aver preso visione dell'<a href='https://tipshere.com/privacy.html' target='_blank'>informativa privacy</a> e autorizzo all'uso dei miei dati personali",
      description: `SignUp field privacy (privacy)`,
    }),
    required: true,
    type: "checkbox",
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "condizioni-generali",
    name: "condizioni-generali",
    label: intl.formatMessage({
      id: "SIGNUP_CONDITIONS1",
      defaultMessage:
        "Dichiaro di aver preso visione e di accettare le <a href='https://tipshere.com/terms.html' target='_blank'>condizioni generali del servizio</a>",
      description: `SignUp field conditions1 (condizioni-generali)`,
    }),
    required: true,
    type: "checkbox",
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "condizioni-generali-2",
    name: "condizioni-generali-2",
    label: intl.formatMessage({
      id: "SIGNUP_CONDITIONS2",
      defaultMessage:
        "Dichiaro di aver preso visione e di accettare tutte le specifiche clausole del contratto",
      description: `SignUp field conditions2 (condizioni-generali-2)`,
    }),
    required: true,
    type: "checkbox",
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
];

export const formFieldsSignIn = (intl: IntlFormatters): Array<FormFields> => [
  {
    id: "email",
    name: "email",
    label: intl.formatMessage({
      id: "SIGNUP_EMAIL",
      defaultMessage: "Email",
      description: `SignUp field Email (email)`,
    }),
    required: true,
    autoComplete: "email",
    type: "email",
    initialValue: "",
    autoFocus: true,
    InputLabelProps: {
      shrink: true,
    },
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: intl.formatMessage({
          id: "GENERAL_EMAIL_INVALID",
          defaultMessage: "Indirizzo email non valido",
          description: `Form field invalid email message`,
        }),
      },
    },
  },
  {
    id: "password",
    name: "password",
    label: intl.formatMessage({
      id: "SIGNUP_PASSWORD",
      defaultMessage: "Password",
      description: `SignUp field Password (password)`,
    }),
    required: true,
    type: "password",
    autoComplete: "current-password",
    initialValue: "",
    autoFocus: false,
    InputLabelProps: {
      shrink: true,
    },
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
];

export const radioFieldProprietario = (intl: IntlFormatters): FormFields => {
  return {
    id: "proprietario",
    name: "proprietario",
    label: "Proprietario",
    initialValue: "no",
    defaultValue: "no",
    type: "radio-group",
    autoFocus: true,
    required: true,
    gridSize: 12,
    children: [
      {
        id: "si",
        name: "si",
        value: "si",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_PROPRIETARIO_KNOW_YES_ANSWER",
          defaultMessage: "Si",
          description: "Segnalazione proprietario field option Si (si)",
        }),
      },
      {
        id: "no",
        name: "no",
        value: "no",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_PROPRIETARIO_KNOW_NO_ANSWER",
          defaultMessage: "No",
          description: "Segnalazione proprietario field option No (no)",
        }),
      },
      {
        id: "isProprietario",
        name: "isProprietario",
        value: "isProprietario",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_PROPRIETARIO_KNOW_I_AM_ANSWER",
          defaultMessage: "Sono io il proprietario",
          description:
            "Segnalazione roprietario field option Sono io il proprietario (isProprietario)",
        }),
      },
    ],
  };
};

export const radioFieldUbicazione = (intl: IntlFormatters): FormFields => {
  return {
    id: "ubicazione",
    name: "ubicazione",
    label: "ubicazione",
    initialValue: "no",
    children: [
      {
        id: "si",
        name: "si",
        value: "si",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_UBICAZIONE_KNOW_YES_ANSWRER",
          defaultMessage: "Si",
          description: "Segnalazione ubicazione field option Si (si)",
        }),
      },
      {
        id: "no",
        name: "no",
        value: "no",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_UBICAZIONE_KNOW_NO_ANSWER",
          defaultMessage: "No",
          description: "Segnalzione ubicazione field option No (no)",
        }),
      },
    ],
  };
};

export const allRadioFields = (intl: IntlFormatters): Array<FormFields> => [
  radioFieldProprietario(intl),
  radioFieldUbicazione(intl),
];

export const formFieldsProprietario = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "nome",
    name: "nome",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_PROPRIETARIO_FNAME",
      defaultMessage: "Nome",
      description: "Segnalazione priprietario field Nome (nome)",
    }),
    required: false,
    autoFocus: true,
    initialValue: "",
    type: "text",
    gridSize: 6,
  },
  {
    id: "cognome",
    name: "cognome",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_PROPRIETARIO_LNAME",
      defaultMessage: "Cognome",
      description: "Segnalazione proprietario field Cognome (cognome)",
    }),
    required: false,
    initialValue: "",
    type: "text",
    gridSize: 6,
  },
  {
    id: "telefono",
    name: "telefono",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_PROPRIETARIO_PHONE_NR",
      defaultMessage: "Telefono",
      description: "Segnalazione proprietariO field Telefono (telefono)",
    }),
    required: false,
    autoComplete: "",
    type: "text",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "email",
    name: "email",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_PROPRIETARIO_EMAIL",
      defaultMessage: "Email",
      description: "Segnalazione proprietario field Email (email)",
    }),
    required: false,
    autoComplete: "email",
    type: "text",
    initialValue: "",
    gridSize: 6,
    errorObject: {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: intl.formatMessage({
          id: "GENERAL_EMAIL_INVALID",
          defaultMessage: "Indirizzo email non valido",
          description: `Form field invalid email message`,
        }),
      },
    },
  },
  {
    id: "privacy",
    name: "privacy",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_PROPRIETARIO_PRIVACY",
      defaultMessage:
        "Dichiaro di avere il diritto e il consenso per fornire questi dati. (Vedi le <a href='https://tipshere.com/terms.html' target='_blank'>condizioni generali del servizio</a>)",
      description: "Segnalazione proprietario field privacy (privacy)",
    }),
    required: true,
    type: "checkbox",
    initialValue: "",
    gridSize: 12,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
];

export const formFieldsImmobile = (intl: IntlFormatters): Array<FormFields> => [
  {
    id: "immobile",
    name: "immobile",
    label: "Immobile",
    initialValue: "",
    defaultValue: "",
    required: true,
    type: "radio-group",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    children: [
      {
        id: "appartamento",
        name: "appartamento",
        value: "appartamento",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_IMMOBILE_OPTION_APPARTAMENTO",
          defaultMessage: "Appartamento",
          description: `Segnalazione immobile field option Appartamento (appartamento)`,
        }),
      },
      {
        id: "villa",
        name: "villa",
        value: "villa",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_IMMOBILE_OPTION_VILLA",
          defaultMessage: "Villa",
          description: `Segnalazione immobile field option Villa (villa)`,
        }),
      },
      {
        id: "terreno",
        name: "terreno",
        value: "terreno",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_IMMOBILE_OPTION_TERENO",
          defaultMessage: "Terreno",
          description: `Segnalazione immobile field option Terreno (terreno)`,
        }),
      },
      {
        id: "commerciale",
        name: "commerciale",
        value: "commerciale",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_IMMOBILE_OPTION_COMMERCIALE",
          defaultMessage: "Commerciale",
          description: `Segnalazione immobile field option Commerciale (commerciale)`,
        }),
      },
    ],
  },
];

export const formFieldsTipologia = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "tipologia",
    name: "tipologia",
    label: "Tipologia immobile",
    initialValue: "vendita",
    defaultValue: "vendita",
    type: "radio-group",
    required: true,
    gridSize: 12,
    children: [
      {
        id: "vendita",
        name: "vendita",
        value: "vendita",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_TIPOLOGIA_OPTION_VENDITA",
          defaultMessage: "Vendita",
          description: `Segnalazione tipologia field option Vendita (vendita)`,
        }),
        disabled: false,
      },
      {
        id: "affitto",
        name: "affitto",
        value: "affitto",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_TIPOLOGIA_OPTION_AFFITTO",
          defaultMessage: "Affitto",
          description: `Segnalazione tipologia field option Affitto (affitto)`,
        }),
        disabled: false,
      },
      {
        id: "ristrutturazione",
        name: "ristrutturazione",
        value: "ristrutturazione",
        label: intl.formatMessage({
          id: "SEGNALAZIONE_TIPOLOGIA_OPTION_RISTRUTTURAZIONE",
          defaultMessage: "Ristrutturazione",
          description: `Segnalazione tipologia field option Ristrutturazione (ristrutturazione)`,
        }),
        disabled: true,
      },
    ],
  },
];

export const formFieldsAddress = (intl: IntlFormatters): Array<FormFields> => [
  {
    id: "citta",
    name: "citta",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_ADDRESS_CITTA",
      defaultMessage: "Citta",
      description: "Segnalazione address field Citta (citta)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 4,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "via",
    name: "via",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_ADDRESS_VIA",
      defaultMessage: "Via",
      description: "Segnalazione address field Via (via)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 6,
  },
  {
    id: "civico",
    name: "civico",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_ADDRESS_CIVICO",
      defaultMessage: "Civico",
      description: "Segnalazione address field Civico (civico)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 2,
  },
  {
    id: "scala",
    name: "scala",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_ADDRESS_SCALA",
      defaultMessage: "Scala",
      description: "Segnalazione address field Scala (scala)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 6,
  },
  {
    id: "piano",
    name: "piano",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_ADDRESS_PIANO",
      defaultMessage: "Piano",
      description: "Segnalazione address field Piano (piano)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 6,
  },
];

export const formFieldsFine = (intl: IntlFormatters): Array<FormFields> => [
  {
    id: "note",
    name: "note",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_FINE_NOTE",
      defaultMessage: "Note",
      description: "Segnalazione fine field Note (note)",
    }),
    initialValue: "",
    required: false,
    multiline: true,
    rows: "4",
  },
  {
    id: "condizioni-generali",
    name: "condizioni-generali",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_FINE_CONDITION",
      defaultMessage:
        "Dichiaro di aver preso visione e di accettare le <a href='https://tipshere.com/terms.html' target='_blank'>condizioni generali del servizio</a>",
      description: "Segnalazione fine field condition (condizioni-generali)",
    }),
    required: true,
    type: "checkbox",
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "condizioni-generali-2",
    name: "condizioni-generali-2",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_FINE_CONDITION2",
      defaultMessage:
        "Dichiaro di aver preso visione e di accettare tutte le specifiche clausole del contratto",
      description: "Segnalazione fine field condition (condizioni-generali-2)",
    }),
    required: true,
    type: "checkbox",
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
];

export const formFieldsFoto: Array<FormFields> = [
  {
    id: "foto",
    name: "foto",
    initialValue: [],
  },
];

export const formAllFieldsSegnalazione = (
  intl: IntlFormatters
): Array<FormFields> =>
  formFieldsTipologia(intl).concat(
    formFieldsProprietario(intl),
    formFieldsAddress(intl),
    formFieldsFoto,
    formFieldsFine(intl),
    formFieldsImmobile(intl)
  );

export const formFieldsAdminSegnalazioneModal = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "note_interne",
    name: "note_interne",
    label: intl.formatMessage({
      id: "SEGNALAZIONE_ADMIN_MODAL",
      defaultMessage: "Note Interne",
      description: `Segnalazione admin field Note Interne (note_interne)`,
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 12,
    multiline: true,
    rows: 4,
  },
];

export const segnalazioneLabels = (intl: IntlFormatters): StringObject => {
  return {
    id: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_ID",
      defaultMessage: "Id",
      description: `Segnalazione label Id`,
    }),
    proprietario: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_PROPRIETARIO",
      defaultMessage: "Proprietario",
      description: `Segnalazione label Proprietario`,
    }),
    ubicazione: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_UBICAZIONE",
      defaultMessage: "Ubicazione",
      description: `Segnalazione label Ubicazione`,
    }),
    note: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_NOTE",
      defaultMessage: "Note",
      description: `Segnalazione label Note`,
    }),
    stato: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_STATO",
      defaultMessage: "Stato",
      description: `Segnalazione label Stato`,
    }),
    commissione: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_COMMISSIONE",
      defaultMessage: "Commissione",
      description: `Segnalazione label Commissione`,
    }),
    percentuale: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_PERCENTUALE",
      defaultMessage: "Percentuale",
      description: `Segnalazione label Percentuale`,
    }),
    foto: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_FOTO",
      defaultMessage: "Foto",
      description: `Segnalazione label Foto`,
    }),
    nota_vocale: intl.formatMessage({
      id: "SEGNALAZIONE_LABEL_NOTA_VOCALE",
      defaultMessage: "Nota vocale",
      description: `Segnalazione label Nota vocale`,
    }),
    scala: intl.formatMessage({
      id: "SEGNALAZIONE_UBICAZIONE_LABEL_SCALA",
      defaultMessage: "Scala",
      description: `Segnalazione label Scala`,
    }),
    piano: intl.formatMessage({
      id: "SEGNALAZIONE_UBICAZIONE_LABEL_PIANO",
      defaultMessage: "Piano",
      description: `Segnalazione label Piano`,
    }),
    segnalatore: intl.formatMessage({
      id: "SEGNALAZIONE_UBICAZIONE_LABEL_SEGNALATORE",
      defaultMessage: "Segnalatore",
      description: `Segnalazione label Segnalatore`,
    }),
    commissione_riconosciuta: intl.formatMessage({
      id: "SEGNALAZIONE_UBICAZIONE_LABEL_COMMISSIONE_RICONOSCIUTA",
      defaultMessage: "Commissione Riconosciuta",
      description: `Segnalazione label Commissione Riconosciuta`,
    }),
  };
};

export const segnalazioneTableHeadLabels = (
  intl: IntlFormatters
): StringObject => {
  return {
    data_inserimento: intl.formatMessage({
      id: "SEGNALAZIONE_LIST_TABLE_HEAD_DATE",
      defaultMessage: "Data Inserimento",
      description: "Segnalazione list table head Data inserimento ",
    }),
    tipo: intl.formatMessage({
      id: "SEGNALAZIONE_LIST_TABLE_HEAD_TIPOLOGIA",
      defaultMessage: "Tipologia",
      description: "Segnalazione list table head Tipologia ",
    }),
    segnalatore: intl.formatMessage({
      id: "SEGNALAZIONE_LIST_TABLE_HEAD_SEGNALATORE",
      defaultMessage: "Segnalatore",
      description: "Segnalazione list table head Segnalatore ",
    }),
    citta: intl.formatMessage({
      id: "SEGNALAZIONE_LIST_TABLE_HEAD_CITTA",
      defaultMessage: "Citta",
      description: "Segnalazione list table head Citta ",
    }),
    stato: intl.formatMessage({
      id: "SEGNALAZIONE_LIST_TABLE_HEAD_STATO",
      defaultMessage: "Stato",
      description: "Segnalazione list table head Stato ",
    }),
    percentuale: intl.formatMessage({
      id: "SEGNALAZIONE_LIST_TABLE_HEAD_PERCENTUALE",
      defaultMessage: "Percentuale",
      description: "Segnalazione list table head Percentuale ",
    }),
    commissione: intl.formatMessage({
      id: "SEGNALAZIONE_LIST_TABLE_HEAD_COMMISSIONE",
      defaultMessage: "Commissione",
      description: "Segnalazione list table head Commissione ",
    }),
  };
};

export const segnalazioneImmobileSuggestionTableHeadLabel = (
  intl: IntlFormatters
): StringObject => {
  return {
    indirizzo: intl.formatMessage({
      id: "SEGNALAZIONE_IMMOBILE_SEARCH_TABLE_HEAD_INDIRIZZO",
      defaultMessage: "Indirizzo",
      description: "Segnalazione search immobile table head label Indirizzo",
    }),
    civico: intl.formatMessage({
      id: "SEGNALAZIONE_IMMOBILE_SEARCH_TABLE_HEAD_CIVICO",
      defaultMessage: "Civico",
      description: "Segnalazione search immobile table head label Civico",
    }),
    citta: intl.formatMessage({
      id: "SEGNALAZIONE_IMMOBILE_SEARCH_TABLE_HEAD_CITTA",
      defaultMessage: "Citta",
      description: "Segnalazione search immobile table head label Citta",
    }),
    piano: intl.formatMessage({
      id: "SEGNALAZIONE_IMMOBILE_SEARCH_TABLE_HEAD_PIANO",
      defaultMessage: "Piano",
      description: "Segnalazione search immobile table head label Piano",
    }),
  };
};
export const formFieldsAddImmobileProprietario = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "nome",
    name: "nome",
    label: intl.formatMessage({
      id: "PROPRIETARIO_NOME",
      defaultMessage: "Nome",
      description: "Proprietario field Nome (nome)",
    }),
    required: false,
    autoFocus: true,
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 6,
  },
  {
    id: "cognome",
    name: "cognome",
    label: intl.formatMessage({
      id: "PROPRIETARIO_COGNOME",
      defaultMessage: "Cognome",
      description: "Proprietario field Cognome (cognome)",
    }),
    required: false,
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 6,
  },
  {
    id: "telefono_cellulare",
    name: "telefono_cellulare",
    label: intl.formatMessage({
      id: "PROPRIETARIO_TEL_CELLULARE",
      defaultMessage: "Telefono cellulare",
      description: "Proprietario field Telefono Cellulare (telefono_cellulare)",
    }),
    required: false,
    onBlur: true,
    autoComplete: "",
    type: "text",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "email",
    name: "email",
    label: intl.formatMessage({
      id: "PROPRIETARIO_EMAIL",
      defaultMessage: "Email",
      description: "Proprietario field Email (email)",
    }),
    required: true,
    onBlur: true,
    autoComplete: "email",
    type: "text",
    initialValue: "",
    gridSize: 6,
    errorObject: {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: intl.formatMessage({
          id: "GENERAL_EMAIL_INVALID",
          defaultMessage: "Indirizzo email non valido",
          description: `Form field invalid email message`,
        }),
      },
    },
  },
  {
    id: "indirizzo",
    name: "indirizzo",
    label: intl.formatMessage({
      id: "PROPRIETARIO_INDIRIZZO",
      defaultMessage: "Indirizzo",
      description: "Proprietario field Indirizzo (indirizzo)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 10,
  },
  {
    id: "civico",
    name: "civico",
    label: intl.formatMessage({
      id: "PROPRIETARIO_CIVICO",
      defaultMessage: "Civico",
      description: "Proprietario field Civico (civico)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 2,
  },
  {
    id: "citta",
    name: "citta",
    label: intl.formatMessage({
      id: "PROPRIETARIO_CITTA",
      defaultMessage: "Città",
      description: "Proprietario field Città (citta)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 6,
  },
  {
    id: "telefono-fisso",
    name: "telefono_fisso",
    label: intl.formatMessage({
      id: "PROPRIETARIO_TEL_FISSO",
      defaultMessage: "Telefono fisso",
      description: "Proprietario field Telefono fisso (telefono_fisso)",
    }),
    required: false,
    autoComplete: "",
    type: "text",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "note",
    name: "note",
    label: intl.formatMessage({
      id: "PROPRIETARIO_NOTE",
      defaultMessage: "Note",
      description: "Proprietario field Note (note)",
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 12,
    multiline: true,
    rows: 4,
  },
];

export const formFieldsAddImmobileContatto = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "nome",
    name: "nome",
    label: intl.formatMessage({
      id: "CONTATTO_NOME",
      defaultMessage: "Nome",
      description: "Contatto field Nome (nome)",
    }),
    required: false,
    autoFocus: true,
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 6,
  },
  {
    id: "cognome",
    name: "cognome",
    label: intl.formatMessage({
      id: "CONTATTO_COGNOME",
      defaultMessage: "Cognome",
      description: "Contatto field Cognome (cognome)",
    }),
    required: false,
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 6,
  },
  {
    id: "telefono_cellulare",
    name: "telefono_cellulare",
    label: intl.formatMessage({
      id: "CONTATTO_TEL_CELLULARE",
      defaultMessage: "Telefono cellulare",
      description: "Contatto field Telefono Cellulare (telefono_cellulare)",
    }),
    required: false,
    onBlur: true,
    autoComplete: "",
    type: "text",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "email",
    name: "email",
    label: intl.formatMessage({
      id: "CONTATTO_EMAIL",
      defaultMessage: "Email",
      description: "Contatto field Email (email)",
    }),
    required: true,
    onBlur: true,
    autoComplete: "email",
    type: "text",
    initialValue: "",
    gridSize: 6,
    errorObject: {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: intl.formatMessage({
          id: "GENERAL_EMAIL_INVALID",
          defaultMessage: "Indirizzo email non valido",
          description: `Form field invalid email message`,
        }),
      },
    },
  },
  {
    id: "note",
    name: "note",
    label: intl.formatMessage({
      id: "CONTATTO_NOTE",
      defaultMessage: "Note",
      description: "Contatto field Note (note)",
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 12,
    multiline: true,
    rows: 4,
  },
];
export const contattoTableHeadLabels = (intl: IntlFormatters): StringObject => {
  return {
    nome: intl.formatMessage({
      id: "CONTATTO_TABLE_HEAD_FNAME",
      defaultMessage: "Nome",
      description: "Contatto table head label Nome",
    }),
    cognome: intl.formatMessage({
      id: "CONTATTO_TABLE_HEAD_LNAME",
      defaultMessage: "Cognome",
      description: "Contatto table head label Cognome",
    }),
    email: intl.formatMessage({
      id: "CONTATTO_TABLE_HEAD_EMAIL",
      defaultMessage: "Email",
      description: "Contatto table head label Email",
    }),
    telefono: intl.formatMessage({
      id: "CONTATTO_TABLE_HEAD_PHONE",
      defaultMessage: "Telefono",
      description: "Contatto table head label Telefono",
    }),
  };
};

export const invitesTableLabels = (intl: IntlFormatters): StringObject => {
  return {
    id: intl.formatMessage({
      id: "CONTATTO_TABLE_HEAD_ID",
      defaultMessage: "ID",
      description: "Invites table head label id",
    }),
    email: intl.formatMessage({
      id: "CONTATTO_TABLE_HEAD_EMAIL",
      defaultMessage: "Email",
      description: "Contatto table head label Email",
    }),
    stato: intl.formatMessage({
      id: "SEGNALAZIONE_LIST_TABLE_HEAD_STATO",
      defaultMessage: "Stato",
      description: "Segnalazione list table head Stato ",
    }),
  };
};
export const usersTableLabels = (intl: IntlFormatters): StringObject => {
  return {
    nome: intl.formatMessage({
      id: "USER_NOME",
      defaultMessage: "Nome",
      description: "User field Nome (nome)",
    }),
    cognome: intl.formatMessage({
      id: "USER_COGNOME",
      defaultMessage: "Cognome",
      description: "User field Cognome (cognome)",
    }),
    data_di_nascita: intl.formatMessage({
      id: "USER_DATA",
      defaultMessage: "Data di nascita",
      description: "User field Data di nascita (data_di_nascita)",
    }),
    email: intl.formatMessage({
      id: "USER_EMAIL",
      defaultMessage: "E-Mail",
      description: "User field E-mail (email)",
    }),
    telefono: intl.formatMessage({
      id: "USER_TELEFONO",
      defaultMessage: "Numero di telefono",
      description: "User field Numero di telefono (telefono)",
    }),
    role: intl.formatMessage({
      id: "USER_RUOLO",
      defaultMessage: "Ruolo",
      description: "User field role (role)",
    }),
    relations: intl.formatMessage({
      id: "USER_RELATIONS",
      defaultMessage: "Relazioni",
      description: "Relations for user (list)",
    }),
  };
};
export const quadrantiTableHeadLabels = (
  intl: IntlFormatters
): StringObject => {
  return {
    id: intl.formatMessage({
      id: "CONTATTO_TABLE_QUADRANTI_HEAD_ID",
      defaultMessage: "ID",
      description: "Quadranti table head label id",
    }),
    nome: intl.formatMessage({
      id: "CONTATTO_TABLE_QUADRANTI_HEAD_LNAME",
      defaultMessage: "Nome",
      description: "Quadranti table head label Nome",
    }),
    immobili: intl.formatMessage({
      id: "CONTATTO_TABLE_QUADRANTI_HEAD_IMMOBILI",
      defaultMessage: "Immobili",
      description: "Quadranti table head label Immobili",
    })
  };
};

export const subscriptionsTableHeadLabels = (
  intl: IntlFormatters
): StringObject => {
  return {
    id: intl.formatMessage({
      id: "SUBSCRIPTION_TABLE_HEAD_FID",
      defaultMessage: "ID",
      description: "Subscriptions table head label id",
    }),
    data_inizio: intl.formatMessage({
      id: "SUBSCRIPTION_TABLE_HEAD_LSTARTDATE",
      defaultMessage: "Data inizio",
      description: "Subscriptions table head label start date",
    }),
    data_fine: intl.formatMessage({
      id: "SUBSCRIPTION_TABLE_HEAD_LENDDATE",
      defaultMessage: "Data fine",
      description: "Subscriptions table head label data fine",
    }),
    quadranti: intl.formatMessage({
      id: "SUBSCRIPTION_TABLE_HEAD_LQUADRANTI",
      defaultMessage: "Quadranti",
      description: "Subscriptions table head label Quadranti",
    }),
    tipologia: intl.formatMessage({
      id: "SUBSCRIPTION_TABLE_HEAD_LTIPOLOGIA",
      defaultMessage: "Tipologia",
      description: "Subscriptions table head label Tipologia",
    }),
    accountFatturazione: intl.formatMessage({
      id: "SUBSCRIPTION_TABLE_HEAD_LACCOUNT",
      defaultMessage: "Account Fatturazione",
      description: "Subscriptions table head label Account Fatturazione",
    }),
    immobili: intl.formatMessage({
      id: "CONTATTO_TABLE_QUADRANTI_HEAD_IMMOBILI",
      defaultMessage: "Immobili",
      description: "Subscription table head label Immobili",
    })
  };
};

export const usersActiveTableHeadLabels = (
  intl: IntlFormatters
): StringObject => {
  return {
    id: intl.formatMessage({
      id: "USERS_ACTIVE_TABLE_HEAD_FID",
      defaultMessage: "ID",
      description: "Users active table head label id",
    }),
    name: intl.formatMessage({
      id: "USERS_ACTIVE_TABLE_HEAD_LNOME",
      defaultMessage: "Name",
      description: "Users active table head label name",
    }),
    email: intl.formatMessage({
      id: "USERS_ACTIVE_TABLE_HEAD_LEMAIL",
      defaultMessage: "Email",
      description: "Users active table head label email",
    }),
    role: intl.formatMessage({
      id: "USERS_ACTIVE_TABLE_HEAD_LROLE",
      defaultMessage: "Ruolo",
      description: "Users active table head label role",
    }),
  };
};

export const usersActiveRole = (intl: IntlFormatters): StringObject => {
  return {
    agent: intl.formatMessage({
      id: "USERS_ROLE_AGENT",
      defaultMessage: "Operatore",
      description: "Users active role agent",
    }),
    manager: intl.formatMessage({
      id: "USERS_ROLE_MANAGER",
      defaultMessage: "Gestore",
      description: "Users active role manager",
    }),
    deleted: intl.formatMessage({
      id: "USERS_ROLE_DELETED",
      defaultMessage: "Cancella",
      description: "Users active role deleted",
    }),
  };
};

export const checkBoxAddImmobileDestinazione = (
  intl: IntlFormatters
): FormFields => {
  return {
    id: "destinazione",
    name: "tipo_segnalazione",
    label: "Destinazione",
    initialValue: "vendita",
    defaultValue: "vendita",
    type: "check-box",
    gridSize: 12,
    children: [
      {
        id: "vendita",
        name: "vendita",
        value: "vendita",
        label: intl.formatMessage({
          id: "IMMOBILE_DESTINAZIONE_OPTION_VENDITA",
          defaultMessage: "Vendita",
          description: "Immobile destinazione option field Vendita (email)",
        }),
        disabled: false,
      },
      {
        id: "affitto",
        name: "affitto",
        value: "affitto",
        label: intl.formatMessage({
          id: "IMMOBILE_DESTINAZIONE_OPTION_AFFITTO",
          defaultMessage: "Affitto",
          description: "Immobile destinazione option field Affitto (affitto)",
        }),
        disabled: false,
      },
      {
        id: "ristrutturazione",
        name: "ristrutturazione",
        value: "ristrutturazione",
        label: intl.formatMessage({
          id: "IMMOBILE_DESTINAZIONE_OPTION_RISTRUTTURAZIONE",
          defaultMessage: "Ristruttirazione",
          description:
            "Immobile destinazione option field Ristrutturazione (ristrutturazione)",
        }),
        disabled: false,
      },
    ],
  };
};

export const formFieldsAddImmobileAddress = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "indirizzo",
    name: "via",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_INDIRIZZO",
      defaultMessage: "Indirizzo",
      description: "Immobile address field Indirizzo (via)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    onBlur: true,
    required: true,
    gridSize: 10,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "civico",
    name: "civico",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_CIVICO",
      defaultMessage: "Civico",
      description: "Immobile address field Civico (civico)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: true,
    onBlur: true,
    gridSize: 2,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "citta",
    name: "citta",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_CITTA",
      defaultMessage: "Città",
      description: "Immobile address field Città (citta)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: true,
    onBlur: true,
    gridSize: 6,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "piano",
    name: "piano",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_PIANO",
      defaultMessage: "Piano",
      description: "Immobile address field Piano (piano)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 6,
  },
  {
    id: "coordinate-geografiche",
    name: "coordinate_geografiche",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_COORDINATE_GEOGRAFICHE",
      defaultMessage: "Coordinate geografiche",
      description:
        "Immobile address field Coordinate geografiche (coordinate_geografiche)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    disabled: true,
    gridSize: 6,
  },
];
export const formFieldsAddImmobileLocationDetails = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "stato",
    name: "paese",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_STATO",
      defaultMessage: "Paese",
      description: "Immobile address field Paese",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    gridSize: 4,
  },
  {
    id: "regione",
    name: "regione",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_REGIONE",
      defaultMessage: "Regione",
      description: "Immobile address field Regione",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    gridSize: 4,
  },
  {
    id: "provincia",
    name: "provincia",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_PROVINCIA",
      defaultMessage: "Provincia",
      description: "Immobile address field Provincia",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    gridSize: 4,
  },
  {
    id: "cap",
    name: "cap",
    label: intl.formatMessage({
      id: "IMMOBILE_ADDRESS_CAP",
      defaultMessage: "CAP",
      description: "Immobile address field CAP",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    gridSize: 4,
  },
];

export const formFieldsAddImmobileDatiCatastali = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "foglio",
    name: "foglio",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_CATASTALI_FOGLIO",
      defaultMessage: "Foglio",
      description: "Immobile dati catastali field Foglio (foglio)",
    }),
    initialValue: "",
    type: "text",
    onBlur: true,
    gridSize: 4,
  },
  {
    id: "particella",
    name: "particella",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_CATASTALI_PARTICELLA",
      defaultMessage: "Particella",
      description: "Immobile dati catastali field Particella (particella)",
    }),
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 4,
  },
  {
    id: "subalterno",
    name: "subalterno",
    onBlur: true,
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_CATASTALI_SUBALTERNO",
      defaultMessage: "Subalterno",
      description: "Immobile dati catastali field Subalterno (subalterno)",
    }),
    initialValue: "",
    type: "text",
    gridSize: 4,
  },
  {
    id: "comune",
    name: "comune",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_CATASTALI_COMUNE",
      defaultMessage: "Comune",
      description: "Immobile dati catastali field Comune (comune)",
    }),
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 4,
  },
];

export const formFiledsAddImobileDatiVendita = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "valore_stimato_vendita",
    name: "valore_stimato_vendita",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_VENDITA",
      defaultMessage: "Valore stimato vendita",
      description: "Immobile dati vendita label",
    }),
    initialValue: "",
    type: "text",
    required: false,
    gridSize: 4,
    filter: "vendita",
    children: [
      {
        id: "val_stimato_vendita_da",
        name: "val_stimato_vendita_da",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_PREZZO_DA",
          defaultMessage: "Da",
          description: "Immobile dati vendita field Prezzo da (da)",
        }),
        initialValue: "",
        type: "text",
        gridSize: 4,
      },
      {
        id: "val_stimato_vendita_a",
        name: "val_stimato_vendita_a",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_PREZZO_A",
          defaultMessage: "A",
          description: "Immobile dati vendita field Prezzo a (a)",
        }),
        initialValue: "",
        type: "text",
        gridSize: 4,
      },
    ],
  },
  {
    id: "stato_vendita",
    name: "stato_vendita",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_VENDITA_STATO",
      defaultMessage: "Stato vendita",
      description: "Immobile dati vendita field Stato vendita (stato_vendita)",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 4,
    filter: "vendita",
    select: true,
    SelectProps: {
      native: true,
    },
    children: [
      {
        id: "stato_vendita_approvato",
        name: "approvato",
        value: "approvato",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_STATO_OPTION_APPROVATO",
          defaultMessage: "Approvato",
          description:
            "Immobile dati vendita field option Approvato (approvato)",
        }),
      },
      {
        id: "stato_vendita_acquisito",
        name: "acquisito",
        value: "acquisito",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_STATO_OPTION_ACQUISITO",
          defaultMessage: "Acquisito",
          description:
            "Immobile dati vendita field option Acquisito (acquisito)",
        }),
      },
      {
        id: "stato_vendita_in_trattativa",
        name: "in_trattativa",
        value: "inTrattativa",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_STATO_OPTION_IN_TRATTATIVA",
          defaultMessage: "In trattativa",
          description:
            "Immobile dati vendita field option In trattativa (in_trattativa)",
        }),
      },
      {
        id: "stato_vendita_venduto",
        name: "venduto",
        value: "venduto",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_STATO_OPTION_VENDUTO",
          defaultMessage: "Venduto",
          description: "Immobile dati vendita field option Venduto (venduto)",
        }),
      },
      {
        id: "stato_vendita_non_acquisito",
        name: "non_acquisito",
        value: "nonAcquisito",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_STATO_OPTION_NON_ACQUISITO",
          defaultMessage: "Non Acquisito",
          description:
            "Immobile dati vendita field option Non Acquisito (non_acquisito)",
        }),
      },
      {
        id: "stato_vendita_venduto_da_altra_agenzia",
        name: "venduto_da_altra_agenzia",
        value: "vendutoDaAltraAgenzia",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_STATO_OPTION_VENDUTO_DA_ALTRA_AGENZIA",
          defaultMessage: "Venduto Da Altra Agenzia",
          description:
            "Immobile dati vendita field option Venduto Da Altra Agenzia (venduto_da_altra_agenzia)",
        }),
      },
      {
        id: "stato_vendita_non_disponibile_vendita",
        name: "non_disponibile",
        value: "nonDisponibile",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_VENDITA_STATO_OPTION_NON_DISPONIBILE",
          defaultMessage: "Non disponibile / non in vendita",
          description:
            "Immobile dati vendita field option Non disponibile / non in vendita (non_disponibile)",
        }),
      },
    ],
  },
  {
    id: "valore-commissione-vendita",
    name: "valore_commissione_vendita",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_VENDITA_COMMISSIONE",
      defaultMessage: "Valore commissione vendita",
      description:
        "Immobile dati vendita field Valore commissione vendita (valore_commissione_vendita)",
    }),
    initialValue: "",
    type: "number",
    required: false,
    gridSize: 4,
    filter: "vendita",
    InputProps: { inputProps: { min: 0 } },
  },
];

export const fromFieldsAddImobileDatiAffitto = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "valore_stimato_affitto",
    name: "valore_stimato_affitto",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_AFFITTO",
      defaultMessage: "Valore stimato affitto",
      description: "Immobile dati affitto label",
    }),
    initialValue: "",
    type: "text",
    required: false,
    gridSize: 4,
    filter: "affitto",
    children: [
      {
        id: "val-da",
        name: "val_stimato_affitto_da",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_PREZZO_DA",
          defaultMessage: "Da",
          description:
            "Immobile dati affitto field Prezzo Da (val_stimato_affitto_da)",
        }),
        initialValue: "",
        type: "text",
        gridSize: 4,
      },
      {
        id: "val-a",
        name: "val_stimato_affitto_a",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_PREZZO_A",
          defaultMessage: "A",
          description:
            "Immobile dati affitto field Prezzo A (val_stimato_affitto_a)",
        }),
        initialValue: "",
        type: "text",
        gridSize: 4,
      },
    ],
  },
  {
    id: "stato-affitto",
    name: "stato_affitto",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_AFFITTO_STATO",
      defaultMessage: "Stato affitto",
      description: "Immobile dati vendita field Stato affitto (stato_affitto)",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 4,
    select: true,
    filter: "affitto",
    SelectProps: {
      native: true,
    },
    children: [
      {
        id: "stato_affitto_approvato",
        name: "approvato",
        value: "approvato",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_STATO_OPTION_APPROVATO",
          defaultMessage: "Approvato",
          description:
            "Immobile dati affitto field option Approvato (approvato)",
        }),
      },
      {
        id: "stato_affitto_acquisito",
        name: "acquisito",
        value: "acquisito",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_STATO_OPTION_ACQUISITO",
          defaultMessage: "Acquisito",
          description:
            "Immobile dati affitto field option Acquisito (acquisito)",
        }),
      },
      {
        id: "stato_affitto_in_trattativa",
        name: "in_trattativa",
        value: "inTrattativa",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_STATO_OPTION_IN_TRATTATIVA",
          defaultMessage: "In trattativa",
          description:
            "Immobile dati affitto field option In trattativa (in_trattativa)",
        }),
      },
      {
        id: "stato_affitto_affittato",
        name: "affittato",
        value: "affittato",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_STATO_OPTION_AFFITTATO",
          defaultMessage: "Affittato",
          description:
            "Immobile dati affitto field option Affittato (affittato)",
        }),
      },
      {
        id: "stato_affitto_non_disponibile_vendita",
        name: "non_disponibile",
        value: "nonDisponibile",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_STATO_OPTION_NON_DISPONIBILE",
          defaultMessage: "Non disponibile",
          description:
            "Immobile dati affitto field option Non disponibile (non_disponibile)",
        }),
      },
    ],
  },
  {
    id: "valore_commissione_affitto",
    name: "valore_commissione_affitto",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_AFFITTO_COMMISSIONE",
      defaultMessage: "Valore commissione affitto",
      description:
        "Immobile dati affitto field Valore commissione affitto (valore_commissione_affitto)",
    }),
    initialValue: "",
    type: "number",
    required: false,
    gridSize: 4,
    filter: "affitto",
    InputProps: { inputProps: { min: 0 } },
  },
  {
    id: "arredato-non-arredato",
    name: "arredato",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_AFFITTO_ARREDATO",
      defaultMessage: "Arredato/non arredato",
      description: "Immobile dati affitto label Arredato/non arredato ",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 4,
    select: true,
    SelectProps: {
      native: true,
    },
    filter: "affitto",
    children: [
      {
        id: "arredato",
        name: "arredato",
        value: "true",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_ARREDATO_OPTION_ARREDATO",
          defaultMessage: "Arredato",
          description:
            "Immobile dati affitto arredato field option arredato (arredato)",
        }),
      },
      {
        id: "non-arredato",
        name: "non_arredato",
        value: "no",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_AFFITTO_ARREDATO_OPTION_NON_ARREDATO",
          defaultMessage: "Non arredato",
          description:
            "Immobile dati affitto arredato field option Non arredato (non_arredato)",
        }),
      },
    ],
  },
  {
    id: "durata-contratto",
    name: "durata_contratto",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_AFFITTO_DURATA",
      defaultMessage: "Durata contratto",
      description:
        "Immobile dati affitto field Durata contratto (durata_contratto)",
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 4,
    filter: "affitto",
  },
];

export const formFieldsAddImobileDatiRistrutturazione = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "valore_stimato_ristrutturazione",
    name: "valore_stimato_ristrutturazione",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_RISTRUTTURAZIONE",
      defaultMessage: "Valore stimato ristrutturazione",
      description: "Immobile dati ristrutturazione label",
    }),
    initialValue: "",
    type: "text",
    required: false,
    filter: "ristrutturazione",
    gridSize: 4,
    children: [
      {
        id: "val_stimato_ristrutturazione_da",
        name: "val_stimato_ristrutturazione_da",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_RISTRUTTURAZIONE_PREZZO_DA",
          defaultMessage: "Da",
          description:
            "Immobile dati ristrutturazione field Prezzo Da (val_stimato_ristrutturazione_da)",
        }),
        initialValue: "",
        type: "text",
        gridSize: 4,
      },
      {
        id: "val_stimato_ristrutturazione_a",
        name: "val_stimato_ristrutturazione_a",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_RISTRUTTURAZIONE_PREZZO_A",
          defaultMessage: "A",
          description:
            "Immobile dati ristrutturazione field Prezzo A (val_stimato_ristrutturazione_a)",
        }),
        initialValue: "",
        type: "text",
        gridSize: 4,
      },
    ],
  },

  {
    id: "stato-ristrutturazione",
    name: "stato_ristrutturazione",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_RISTRUTTURAZIONE_STATO",
      defaultMessage: "Stato ristrutturazione",
      description:
        "Immobile dati ristrutturazione field Stato ristrutturazione (stato_ristrutturazione)",
    }),
    initialValue: "",
    type: "select",
    required: true,
    typeDestinazione: "ristrutturazione",
    gridSize: 4,
    filter: "ristrutturazione",
    select: true,
    SelectProps: {
      native: true,
    },
    children: [
      {
        id: "ristrutturazione-approvato",
        name: "approvato",
        value: "approvato",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_RISTRUTTURAZIONE_STATO_OPTION_APPROVATO",
          defaultMessage: "Approvato",
          description:
            "Immobile dati ristrutturazione field option Approvato (approvato)",
        }),
      },
      {
        id: "stato_rist_in_trattativa",
        name: "in_trattativa",
        value: "inTrattativa",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_RISTRUTTURAZIONE_STATO_OPTION_IN_TRATTATIVA",
          defaultMessage: "In trattativa",
          description:
            "Immobile dati ristrutturazione field option In trattativa (in_trattativa)",
        }),
      },
      {
        id: "stato_rist_appaltato",
        name: "appaltato",
        value: "appaltato",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_RISTRUTTURAZIONE_STATO_OPTION_APPALTATO",
          defaultMessage: "Appaltato",
          description:
            "Immobile dati ristrutturazione field option Appaltato (appaltato)",
        }),
      },
      {
        id: "stato_rist_non_disponibile_vendita",
        name: "non_disponibile",
        value: "nonDisponibile",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_RISTRUTTURAZIONE_STATO_OPTION_NON_DISPONIBILE",
          defaultMessage: "Non disponible",
          description:
            "Immobile dati ristrutturazione field option Non disponibile (non_disponibile)",
        }),
      },
    ],
  },
  {
    id: "valore-commissione-ristrutturazione",
    name: "valore_commissione_ristrutturazione",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_RISTRUTTURAZIONE_COMMISSIONE",
      defaultMessage: "Valore commissione ristrutturazione",
      description:
        "Immobile dati affitto field Valore commissione ristrutturazione (valore_commissione_ristrutturazione)",
    }),
    initialValue: "",
    type: "number",
    required: false,
    gridSize: 4,
    filter: "ristrutturazione",
    InputProps: { inputProps: { min: 0 } },
  },
  {
    id: "tipo-ristrutturazione",
    name: "tipo_ristrutturazione",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_RISTRUTTURAZIONE_TIPO",
      defaultMessage: "Ristrutturazione completa o parziale",
      description:
        "Immobile dati affitto label Ristrutturazione completa o parziale",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 4,
    select: true,
    SelectProps: {
      native: true,
    },
    filter: "ristrutturazione",
    children: [
      {
        id: "completa",
        name: "completa",
        value: "completa",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_RISTRUTTURAZIONE_TIPO_OPTION_COMPLETA",
          defaultMessage: "Completa",
          description:
            "Immobile dati affitto tipo field option Completa (completa)",
        }),
      },
      {
        id: "parziale",
        name: "parziale",
        value: "parziale",
        label: intl.formatMessage({
          id: "IMMOBILE_DATI_RISTRUTTURAZIONE_TIPO_OPTION_PARZIALE",
          defaultMessage: "Parziale",
          description:
            "Immobile dati affitto tipo field option Parziale (parziale)",
        }),
      },
    ],
  },
  {
    id: "anno_costruzione",
    name: "anno_costruzione",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_RISTRUTTURAZIONE_COSTRUZIONE",
      defaultMessage: "Anno costruzione",
      description:
        "Immobile dati ristrutturazione field Anno costruzione (anno_costruzione)",
    }),
    initialValue: "",
    required: false,
    type: "number",
    gridSize: 4,
    filter: "ristrutturazione",
  },
  {
    id: "anno_ultima_ristrutturazione",
    name: "anno_ultima_ristrutturazione",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_RISTRUTTURAZIONE_ULTIMA_COSTRUZIONE",
      defaultMessage: "Anno ultima ristrutturazione",
      description:
        "Immobile dati ristrutturazione field Anno ultima ristrutturazione (anno_ultima_ristrutturazione)",
    }),
    initialValue: "",
    required: false,
    type: "number",
    gridSize: 4,
    filter: "ristrutturazione",
  },
  {
    id: "interventi_da_effettuare",
    name: "interventi_da_effettuare",
    label: intl.formatMessage({
      id: "IMMOBILE_DATI_RISTRUTTURAZIONE_INTERVENTI",
      defaultMessage: "Interventi da effettuare",
      description:
        "Immobile dati ristrutturazione field Interventi da effettuare (interventi_da_effettuare)",
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 12,
    filter: "ristrutturazione",
  },
];

export const formFieldsDestinazioneImmobile = (
  intl: IntlFormatters
): {
  [key: string]: Array<FormFields>;
} => {
  return {
    vendita: formFiledsAddImobileDatiVendita(intl),
    affitto: fromFieldsAddImobileDatiAffitto(intl),
    ristrutturazione: formFieldsAddImobileDatiRistrutturazione(intl),
  };
};

export const formFieldsAddImmobile = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "mq_interni",
    name: "mq_interni",
    label: intl.formatMessage({
      id: "IMMOBILE_MQ_INTERNI",
      defaultMessage: "Mq interni",
      description: "Immobile field Mq interni (mq_interni)",
    }),
    initialValue: "",
    type: "text",
    required: false,
    gridSize: 4,
  },
  {
    id: "mq_esterni",
    name: "mq_esterni",
    label: intl.formatMessage({
      id: "IMMOBILE_MQ_ESTERNI",
      defaultMessage: "Mq esterni",
      description: "Immobile field Mq esterni (mq_esterni)",
    }),
    initialValue: "",
    type: "text",
    required: false,
    gridSize: 4,
  },
  {
    id: "numero_vani",
    name: "numero_vani",
    label: intl.formatMessage({
      id: "IMMOBILE_VANI",
      defaultMessage: "Numero di vani",
      description: "Immobile field Numero di vani (numero_vani)",
    }),
    initialValue: "",
    type: "number",
    required: false,
    gridSize: 4,
  },
  {
    id: "spese_condominiali",
    name: "spese_condominiali",
    label: intl.formatMessage({
      id: "IMMOBILE_SPESE_CONDOMINIALI",
      defaultMessage: "Spese condominiali",
      description: "Immobile field Spese condominiali (spese_condominiali)",
    }),
    initialValue: "",
    type: "text",
    required: false,
    gridSize: 4,
  },
  {
    id: "categoria_energetica",
    name: "categoria_energetica",
    label: intl.formatMessage({
      id: "IMMOBILE_CATEGORIA_ENERGETICA",
      defaultMessage: "Categoria energetica",
      description: "Immobile field Categoria energetica (categoria_energetica)",
    }),
    initialValue: "",
    type: "text",
    required: false,
    gridSize: 4,
  },
  {
    id: "box_posto_auto",
    name: "box_posto_auto",
    label: intl.formatMessage({
      id: "IMMOBILE_POSTO_AUTO",
      defaultMessage: "Box / posto auto",
      description: "Immobile label Box / posto auto",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 4,
    select: true,
    SelectProps: {
      native: true,
    },
    children: [
      {
        id: "box",
        name: "box",
        value: "box",
        label: intl.formatMessage({
          id: "IMMOBILE_POSTO_AUTO_OPTON_BOX",
          defaultMessage: "Box",
          description: "Immobile posto auto field option Box (box)",
        }),
      },
      {
        id: "posto",
        name: "posto",
        value: "posto",
        label: intl.formatMessage({
          id: "IMMOBILE_POSTO_AUTO_OPTION_POSTO",
          defaultMessage: "Posto",
          description: "Immobile poso auto field option Posto (posto)",
        }),
      },
    ],
  },
  {
    id: "ascensore",
    name: "ascensore",
    label: intl.formatMessage({
      id: "IMMOBILE_ASCENSORE",
      defaultMessage: "Con/senza ascensore",
      description: "Immobile label Con/senza ascensore",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 4,
    select: true,
    SelectProps: {
      native: true,
    },
    children: [
      {
        id: "ascensore_si",
        name: "ascensore_si",
        value: "true",
        label: intl.formatMessage({
          id: "IMMOBILE_ASCENSORE_OPTION_SI",
          defaultMessage: "Con ascensore",
          description:
            "Immobile ascensore field option Con ascensore (ascensore_si)",
        }),
      },
      {
        id: "ascensore_no",
        name: "ascensore_no",
        value: "no",
        label: intl.formatMessage({
          id: "IMMOBILE_ASCENSORE_OPTION_NO",
          defaultMessage: "Senza ascensore",
          description:
            "Immobile ascensore field option Senza ascensore (ascensore_no)",
        }),
      },
    ],
  },
  {
    id: "tipologia_immobile",
    name: "tipo_immobile",
    label: intl.formatMessage({
      id: "IMMOBILE_TIPO",
      defaultMessage: "Tipologia immobile",
      description: "Immobile label Tipologia immobile",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 4,
    select: true,
    SelectProps: {
      native: true,
    },
    children: [
      {
        id: "appartamento",
        name: "appartamento",
        value: "appartamento",
        label: intl.formatMessage({
          id: "IMMOBILE_TIPO_OPTION_APPARTAMENTO",
          defaultMessage: "Appartamento",
          description: "Immobile tipo field option Appartamento (appartamento)",
        }),
      },
      {
        id: "villa",
        name: "villa",
        value: "villa",
        label: intl.formatMessage({
          id: "IMMOBILE_TIPO_OPTION_VILLA",
          defaultMessage: "Villa",
          description: "Immobile tipo field option Villa (villa)",
        }),
      },
      {
        id: "terreno",
        name: "terreno",
        value: "terreno",
        label: intl.formatMessage({
          id: "IMMOBILE_TIPO_OPTION_TERRENO",
          defaultMessage: "Terreno",
          description: "Immobile tipo field option Terreno (terreno)",
        }),
      },
      {
        id: "commerciale",
        name: "commerciale",
        value: "commerciale",
        label: intl.formatMessage({
          id: "IMMOBILE_TIPO_OPTION_COMMERCIALE",
          defaultMessage: "Commerciale",
          description: "Immobile tipo field option Commerciale (commerciale)",
        }),
      },
    ],
  },
  {
    id: "note",
    name: "note",
    label: intl.formatMessage({
      id: "IMMOBILE_NOTE",
      defaultMessage: "Note Interne",
      description: "Immobile field Note (note)",
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 12,
    multiline: true,
    rows: 4,
  },
  {
    id: "note_agenzia",
    name: "note_agenzia",
    label: intl.formatMessage({
      id: "IMMOBILE_NOTE_AGENZIA",
      defaultMessage: "Note Interne",
      description: "Immobile field Note Agenzia (note_agenzia)",
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 12,
    multiline: true,
    rows: 4,
  },
  {
    id: "note_comuni",
    name: "note_comuni",
    label: intl.formatMessage({
      id: "IMMOBILE_NOTE_INTERNE",
      defaultMessage: "Note",
      description: "Immobile field Note (note_comuni)",
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 12,
    multiline: true,
    rows: 4,
  },
];

export const formFieldsSubscriptionAccounts = (
  intl: IntlFormatters
): FormFields => {
  return {
    id: "accountFatturazioneId",
    name: "accountFatturazioneId",
    label: intl.formatMessage({
      id: "ACCOUNTS_DI_FATTURAZIONE",
      defaultMessage: "ACCOUNT DI FATTURAZIONE",
      description: "Account di fatturazione label",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 6,
    select: true,
    SelectProps: {
      native: true,
    },
    children: [],
  };
};

export const formFieldsAddImmobileQuadranti = (
  intl: IntlFormatters
): FormFields => {
  return {
    id: "quadranti",
    name: "quadranti",
    label: intl.formatMessage({
      id: "QUADRANTI",
      defaultMessage: "Quadranti",
      description: "Quadranti label",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 6,
    select: true,
    SelectProps: {
      native: true,
    },
    children: [],
  };
};

export const formAllFieldsAddImmobile = (
  intl: IntlFormatters
): Array<FormFields> =>
  formFieldsAddImmobile(intl).concat(
    formFieldsAddImmobileAddress(intl),
    [formFieldsAddImmobileQuadranti(intl)],
    formFieldsAddImmobileLocationDetails(intl),
    formFieldsAddImmobileProprietario(intl),
    formFieldsAddImmobileDatiCatastali(intl),
    formFiledsAddImobileDatiVendita(intl),
    fromFieldsAddImobileDatiAffitto(intl),
    formFieldsAddImobileDatiRistrutturazione(intl)
  );

export const segnalazioneStatoLabels = (intl: IntlFormatters): StringObject => {
  return {
    pending: intl.formatMessage({
      id: "SEGNALAZIONE_STATO_PENDING",
      defaultMessage: "In attesa",
    }),
    workingOn: intl.formatMessage({
      id: "SEGNALAZIONE_STATO_WORKINGON",
      defaultMessage: "In lavorazione",
    }),
    accepted: intl.formatMessage({
      id: "SEGNALAZIONE_STATO_ACCEPTED",
      defaultMessage: "Accettata",
    }),
    reject: intl.formatMessage({
      id: "SEGNALAZIONE_STATO_REJECT",
      defaultMessage: "Rifiutata",
    }),
  };
};

export const segnalazioneStatoRejectLabels = (
  intl: IntlFormatters
): StringObject => {
  return {
    duplicated: intl.formatMessage({
      id: "SEGNALAZIONE_STATO_DUPLICATED",
      defaultMessage: "Duplicato",
    }),
    insufficient_data: intl.formatMessage({
      id: "SEGNALAZIONE_STATO_INSUFFICIENT_DATA",
      defaultMessage: "Dati insufficienti",
    }),
  };
};

export const formUserProfile = (intl: IntlFormatters): Array<FormFields> => [
  {
    id: "nome",
    name: "nome",
    label: intl.formatMessage({
      id: "USER_NOME",
      defaultMessage: "Nome",
      description: "User field Nome (nome)",
    }),
    required: true,
    initialValue: "",
    gridSize: 4,
    type: "text",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    InputLabelProps: {
      shrink: true,
    },
  },
  {
    id: "cognome",
    name: "cognome",
    label: intl.formatMessage({
      id: "USER_COGNOME",
      defaultMessage: "Cognome",
      description: "User field Cognome (cognome)",
    }),
    required: true,
    gridSize: 4,
    initialValue: "",
    type: "text",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    InputLabelProps: {
      shrink: true,
    },
  },
  {
    id: "email",
    name: "email",
    label: intl.formatMessage({
      id: "USER_EMAIL",
      defaultMessage: "E-Mail",
      description: "User field E-mail (email)",
    }),
    required: true,
    gridSize: 4,
    autoComplete: "email",
    type: "text",
    initialValue: "",
    disabled: true,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: intl.formatMessage({
          id: "GENERAL_EMAIL_INVALID",
          defaultMessage: "Indirizzo email non valido",
          description: `Form field invalid email message`,
        }),
      },
    },
    InputLabelProps: {
      shrink: true,
    },
  },
  {
    id: "date",
    name: "data_di_nascita",
    label: intl.formatMessage({
      id: "USER_DATA",
      defaultMessage: "Data di nascita",
      description: "User field Data di nascita (data_di_nascita)",
    }),
    required: true,
    type: "date",
    gridSize: 4,
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      max: `${getAge18()}`,
    },
  },
  {
    id: "telefono",
    name: "telefono",
    label: intl.formatMessage({
      id: "USER_TELEFONO",
      defaultMessage: "Numero di telefono",
      description: "User field Numero di telefono (telefono)",
    }),
    required: false,
    autoComplete: "",
    type: "text",
    initialValue: "",
    gridSize: 4,
  },
  {
    id: "professione",
    name: "professione",
    label: intl.formatMessage({
      id: "USER_PROFESSIONE",
      defaultMessage: "Professione",
      description: "User field Professione (professione)",
    }),
    required: false,
    initialValue: "",
    type: "text",
    gridSize: 4,
  },
  {
    id: "codice_fiscale",
    name: "codice_fiscale",
    label: intl.formatMessage({
      id: "USER_CODICE_FISCALE",
      defaultMessage: "Codice fiscale",
      description: "User field Codice fiscale (codice_fiscale)",
    }),
    required: false,
    gridSize: 6,
    initialValue: "",
    type: "text",
  },
  {
    id: "iban",
    name: "iban",
    label: intl.formatMessage({
      id: "USER_IBAN",
      defaultMessage: "IBAN",
      description: "User field IBAN (iban)",
    }),
    gridSize: 6,
    required: false,
    initialValue: "",
    type: "text",
  },
  {
    id: "language",
    name: "language",
    label: intl.formatMessage({
      id: "USER_LANGUAGE",
      defaultMessage: "Lingua",
      description: "User label Lingua",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 2,
    typeDestinazione: "all",
    select: true,
    SelectProps: {
      native: true,
    },
    children: [
      {
        id: "it",
        name: "it",
        value: "it",
        label: "It",
      },
      {
        id: "en",
        name: "en",
        value: "en",
        label: "En",
      },
    ],
  },
];

export const formFieldsSearchImmobile = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "citta",
    name: "citta",
    label: intl.formatMessage({
      id: "IMMOBILE_SEARCH_CITTA",
      defaultMessage: "Città",
      description: "Immobile search field Città (citta)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 4,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "via",
    name: "via",
    label: intl.formatMessage({
      id: "IMMOBILE_SEARCH_VIA",
      defaultMessage: "Via",
      description: "Immobile search field Via (via)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 6,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "civico",
    name: "civico",
    label: intl.formatMessage({
      id: "IMMOBILE_SEARCH_CIVICO",
      defaultMessage: "Civico",
      description: "Immobile search field Civico (civico)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 2,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
];

export const immobileDatiCatastaliError = (
  intl: IntlFormatters
): StringObject => {
  return {
    message: intl.formatMessage({
      id: "IMMOBILE_DATI_CATASTALI_ERROR",
      defaultMessage: "Inserire tutti i 4 campi dei dati catastali o nessuno!",
      description: "Immobile dati catastali error message",
    }),
  };
};
export const immobileValoreComisioneError = (
  intl: IntlFormatters
): StringObject => {
  return {
    message: intl.formatMessage({
      id: "GENERAL_NOT_NEGATIVE",
      defaultMessage: "Valore commissione non può essere un valore negativo",
      description: `Form field invalid email message`,
    }),
  };
};

export const immobiliContattiTableHeadLabels = (
  intl: IntlFormatters
): StringObject => {
  return {
    id: intl.formatMessage({
      id: "CONTATTI_IMMOBILE_ID",
      defaultMessage: "Id",
      description: "Contatti immobili table head Id",
    }),
    indirizzo: intl.formatMessage({
      id: "CONTATTI_IMMOBILE_INDIRIZZO",
      defaultMessage: "Indirizzo",
      description: "Contatti immobili table head Indirizzo",
    }),
    civico: intl.formatMessage({
      id: "CONTATTI_IMMOBILE_CIVICO",
      defaultMessage: "Civico",
      description: "Contatti immobili table head Civico",
    }),
    citta: intl.formatMessage({
      id: "CONTATTI_IMMOBILE_CITTA",
      defaultMessage: "Città",
      description: "Contatti immobili table head Città",
    }),
    piano: intl.formatMessage({
      id: "CONTATTI_IMMOBILE_PIANO",
      defaultMessage: "Piano",
      description: "Contatti immobili table head Piano",
    }),
  };
};

export const immobiliTableLables = (intl: IntlFormatters): StringObject => {
  return {
    id: intl.formatMessage({
      id: "IMMOBILE_ID",
      defaultMessage: "Id",
      description: "Immobile table head Id",
    }),
    tipo: intl.formatMessage({
      id: "IMMOBILE_TIPOLOGIA",
      defaultMessage: "Tipologia",
      description: "Immobile table head Tipologia",
    }),
    proprietario: intl.formatMessage({
      id: "IMMOBILE_PROPRIETARIO",
      defaultMessage: "Proprietario",
      description: "Immobile table head Proprietario",
    }),
    indirizzo: intl.formatMessage({
      id: "IMMOBILE_INDIRIZZO",
      defaultMessage: "Indirizzo",
      description: "Immobile table head Indirizzo",
    }),
    stato: intl.formatMessage({
      id: "IMMOBILE_STATO",
      defaultMessage: "Stato",
      description: "Immobile table head Stato",
    }),
    pubblicazione: intl.formatMessage({
      id: "IMMOBILE_PUBBLICAZIONE",
      defaultMessage: "Pubblicazione",
      description: "Immobile table head Pubblicazione",
    }),
    via: intl.formatMessage({
      id: "IMMOBILE_VIA",
      defaultMessage: "Via",
      description: "Immobile table head Via",
    }),
    citta: intl.formatMessage({
      id: "IMMOBILE_CITTA",
      defaultMessage: "Città",
      description: "Immobile table Città",
    }),
    civico: intl.formatMessage({
      id: "IMMOBILE_CIVICO",
      defaultMessage: "Civico",
      description: "Immobile table Civico",
    }),
    vendita: intl.formatMessage({
      id: "IMMOBILE_VENDITA",
      defaultMessage: "Vendita",
      description: "Immobile table Vendita",
    }),
    affitto: intl.formatMessage({
      id: "IMMOBILE_AFFITTO",
      defaultMessage: "Affitto",
      description: "Immobile table Affitto",
    }),
    ristrutturazione: intl.formatMessage({
      id: "IMMOBILE_RISTRUTTURAZIONE",
      defaultMessage: "Ristrutturazione",
      description: "Immobile table Ristrutturazione",
    }),
    email: intl.formatMessage({
      id: "IMMOBILE_EMAIL",
      defaultMessage: "Email",
      description: "Immobile table Email",
    }),
    nome: intl.formatMessage({
      id: "IMMOBILE_NOME",
      defaultMessage: "Nome",
      description: "Immobile table Nome",
    }),
    cognome: intl.formatMessage({
      id: "IMMOBILE_COGNOME",
      defaultMessage: "Cognome",
      description: "Immobile table Cognome",
    }),
    telefono: intl.formatMessage({
      id: "IMMOBILE_TELEFONO",
      defaultMessage: "Telefono",
      description: "Immobile table Telefono",
    }),
    dati_catastali: intl.formatMessage({
      id: "IMMOBILE_DATI_CATASTALI",
      defaultMessage: "Dati Catastali",
      description: "Immobile dati catastali",
    }),
    foglio: intl.formatMessage({
      id: "IMMOBILE_TABLE_HEAD_DATI_CATASTALI_FOGLIO",
      defaultMessage: "Foglio",
      description: "Immobile dati catastali foglio",
    }),
    particella: intl.formatMessage({
      id: "IMMOBILE_DATI_CATASTALI_PARTICELA",
      defaultMessage: "Particella",
      description: "Immobile dati catastali Particella",
    }),
    subalterno: intl.formatMessage({
      id: "IMMOBILE_TABLE_HEAD_DATI_CATASTALI_SUBALTERNO",
      defaultMessage: "Subalterno",
      description: "Immobile dati catastali subalterno",
    }),
    comune: intl.formatMessage({
      id: "IMMOBILE_TABLE_HEAD_DATI_CATASTALI_COMUNE",
      defaultMessage: "Comune",
      description: "Immobile dati catastali Comune",
    }),
    mq_interni: intl.formatMessage({
      id: "IMMOBILE_DATI_MQ_INTERNI",
      defaultMessage: "Mq interne",
      description: "Immobile mq interne",
    }),
    mq_esterni: intl.formatMessage({
      id: "IMMOBILE_DATI_MQ_ESTERNI",
      defaultMessage: "Mq esterne",
      description: "Immobile mq esterne",
    }),
    numero_vani: intl.formatMessage({
      id: "IMMOBILE_DATI_NR_VANI",
      defaultMessage: "Numero di vani",
      description: "Immobile numero di vani",
    }),
    spese_condominiali: intl.formatMessage({
      id: "IMMOBILE_DATI_SPESE_CONDOMINIALI",
      defaultMessage: "Spese condominiali",
      description: "Immobile spese condominiali",
    }),
    categoria_energetica: intl.formatMessage({
      id: "IMMOBILE_DATI_CATEGORIA_ENERGETICA",
      defaultMessage: "Categoria energetica",
      description: "Immobile categoria energetica",
    }),
    box_posto_auto: intl.formatMessage({
      id: "IMMOBILE_DATI_BOX_POSTO_AUTO",
      defaultMessage: "Box/posto auto",
      description: "Immobile box posto auto",
    }),
    note: intl.formatMessage({
      id: "IMMOBILE_DATI_CATASTALI_NOTE",
      defaultMessage: "Note",
      description: "Immobile dati catastali Note",
    }),
  };
};

export const formFieldsResetPaswword = (
  intl: IntlFormatters
): Array<FormFields> => {
  return [
    {
      id: "current_password",
      name: "current_password",
      label: intl.formatMessage({
        id: "USER_CHANGE_PASSWORD_CURRENT_PASSWORD",
        defaultMessage: "Password attuale",
        description: `User profile reset password field Password attuale (current_password)`,
      }),
      required: true,
      type: "password",
      initialValue: "",
      errorObject: {
        required: intl.formatMessage({
          id: "GENERAL_MANDATORY_FIELD",
          defaultMessage: "Questo campo è obbligatorio",
          description: `Form field mandatory message`,
        }),
      },
    },
    {
      id: "new_password",
      name: "new_password",
      label: intl.formatMessage({
        id: "USER_CHANGE_PASSWORD_NEW_PASSWORD",
        defaultMessage: "Nuova password",
        description: `User profile reset password field Nuova password (new_password)`,
      }),
      required: true,
      type: "password",
      initialValue: "",
      errorObject: {
        required: intl.formatMessage({
          id: "GENERAL_MANDATORY_FIELD",
          defaultMessage: "Questo campo è obbligatorio",
          description: `Form field mandatory message`,
        }),
        pattern: {
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
          message: intl.formatMessage({
            id: "SIGNUP_PASSWORD_VALIDATION",
            defaultMessage:
              "La password deve essere compresa tra 6 e 20 con almeno una cifra numerica, una lettera maiuscola e una minuscola",
            description: `Form field invalid email message`,
          }),
        },
      },
    },
    {
      id: "repeat_password",
      name: "repeat_password",
      label: intl.formatMessage({
        id: "USER_CHANGE_PASSWORD_REPEAT_PASSWORD",
        defaultMessage: "Ripeti password",
        description: `User profile reset password field Nuova password (repeat_password)`,
      }),
      required: true,
      type: "password",
      initialValue: "",
      errorObject: {
        required: intl.formatMessage({
          id: "GENERAL_MANDATORY_FIELD",
          defaultMessage: "Questo campo è obbligatorio",
          description: `Form field mandatory message`,
        }),
      },
    },
  ];
};

export const resetPasswordErrorMessages = (
  intl: IntlFormatters
): StringObject => {
  return {
    not_equals: intl.formatMessage({
      id: "USER_CHANGE_PASSWORD_ERROR_NOT_EQUALS",
      defaultMessage: "Password non uguali",
      description:
        "User reset password error when repeat password and new password are note equals",
    }),
    wrong_password: intl.formatMessage({
      id: "USER_CHANGE_PASSWORD_ERROR_WRONG_PASSWORD",
      defaultMessage: "Password errata",
      description: "User reset password error when current password is wrong",
    }),
  };
};

export const formFieldsAccountFatturazione = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "ragione_sociale",
    name: "ragione_sociale",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_RAGIONE_SOCIALE",
      defaultMessage: "Ragione Sociale",
      description:
        "Account Fatturazione field Ragione Sociale (ragione_sociale)",
    }),
    required: false,
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 6,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "p_iva",
    name: "p_iva",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_P_IVA",
      defaultMessage: "P.IVA",
      description: "Account Fatturazione field P.IVA (p_iva)",
    }),
    required: false,
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 6,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "c_f",
    name: "c_f",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_C_F",
      defaultMessage: "C.F.",
      description: "Account Fatturazione field C.F. (c_f)",
    }),
    required: false,
    onBlur: true,
    initialValue: "",
    type: "text",
    gridSize: 6,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "email",
    name: "email",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_EMAIL",
      defaultMessage: "Email",
      description: "Account Fatturazione field Email (email)",
    }),
    required: true,
    onBlur: true,
    autoComplete: "email",
    type: "text",
    initialValue: "",
    gridSize: 6,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: intl.formatMessage({
          id: "GENERAL_EMAIL_INVALID_EMAIL",
          defaultMessage: "Indirizzo email non valido",
          description: `Form field invalid email message`,
        }),
      },
    },
  },
  {
    id: "pec",
    name: "pec",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_PEC",
      defaultMessage: "PEC",
      description: "Account Fatturazione field PEC (pec)",
    }),
    required: true,
    onBlur: true,
    autoComplete: "email",
    type: "text",
    initialValue: "",
    gridSize: 6,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: intl.formatMessage({
          id: "GENERAL_EMAIL_INVALID",
          defaultMessage: "Indirizzo email non valido",
          description: `Form field invalid email message`,
        }),
      },
    },
  },
  {
    id: "sdi",
    name: "sdi",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_SDI",
      defaultMessage: "SDI",
      description: "Account Fatturazione field SDI (sdi)",
    }),
    required: false,
    autoComplete: "",
    type: "text",
    initialValue: "",
    gridSize: 6,
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
  {
    id: "indirizzo",
    name: "indirizzo",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_IDIRIZZO",
      defaultMessage: "Indirizzo",
      description: "Account Fatturazione field Indirizzo (indirizzo)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 10,
  },
  {
    id: "civico",
    name: "civico",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_CIVICO",
      defaultMessage: "Civico",
      description: "Account Fatturazione field Civico (civico)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 2,
  },
  {
    id: "citta",
    name: "citta",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_CITTA",
      defaultMessage: "Città",
      description: "Account Fatturazione field Città (citta)",
    }),
    initialValue: "",
    type: "text",
    autoComplete: "",
    required: false,
    gridSize: 6,
  },
  {
    id: "telefono",
    name: "telefono",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_TEL_CEL",
      defaultMessage: "Telefono cellulare",
      description: "Account Fatturazione field Telefono Cellulare (telefono)",
    }),
    required: false,
    onBlur: true,
    autoComplete: "",
    type: "text",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "sito",
    name: "sito",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_SITO",
      defaultMessage: "Sito Internet",
      description: "Account Fatturazione field SDI (sito)",
    }),
    required: false,
    autoComplete: "",
    type: "text",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "dipendenti_collaboratori",
    name: "dipendenti_collaboratori",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_DIPENDENTI_COLLABORATORI",
      defaultMessage: "Numero dipendenti/collaboratori agenti",
      description:
        "Account Fatturazione field Numero dipendenti/collaboratori agenti (dipendenti_collaboratori)",
    }),
    required: false,
    autoComplete: "",
    type: "number",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "media_transazioni_annue",
    name: "media_transazioni_annue",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_SITO_TRANSAZIONI",
      defaultMessage: "Media transazioni annue",
      description:
        "Account Fatturazione field Media transazioni annue (media_transazioni_annue)",
    }),
    required: false,
    autoComplete: "",
    type: "number",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "anno_inizio_attivita",
    name: "anno_inizio_attivita",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_SITO_ANNO_INIZIO_ATTIVITA",
      defaultMessage: "Anno inizio attività",
      description:
        "Account Fatturazione field Anno inizio attività (anno_inizio_attivita)",
    }),
    required: false,
    autoComplete: "",
    type: "number",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "nr_patentino_agente",
    name: "nr_patentino_agente",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_SITO_NR_PATENTINO_AGENTE",
      defaultMessage: "Nr. patentino agente immobiliare",
      description:
        "Account Fatturazione field Anno inizio attività (nr_patentino_agente)",
    }),
    required: false,
    autoComplete: "",
    type: "string",
    initialValue: "",
    gridSize: 6,
  },
  {
    id: "note",
    name: "note",
    label: intl.formatMessage({
      id: "ACCOUNT_FATTURAZIONE_NOTE",
      defaultMessage: "Note",
      description: "Account Fatturazione field Note (note)",
    }),
    initialValue: "",
    required: false,
    type: "text",
    gridSize: 12,
    multiline: true,
    rows: 4,
  },
];

export const AccountFatturazioneTableHeadLabels = (
  intl: IntlFormatters
): StringObject => {
  return {
    ragione_sociale: intl.formatMessage({
      id: "ACCOUNT_SEGNALAZIONE_TABLE_HEAD_RAGIONE_SOCIALE",
      defaultMessage: "Ragione Sociale",
      description: "Account segnalazione table head label Ragione Sociale",
    }),
    p_iva: intl.formatMessage({
      id: "ACCOUNT_SEGNALAZIONE_TABLE_HEAD_P_IVA",
      defaultMessage: "P.IVA",
      description: "Account segnalazione table head label ",
    }),
    c_f: intl.formatMessage({
      id: "ACCOUNT_SEGNALAZIONE_TABLE_HEAD_C_F",
      defaultMessage: "C.F.",
      description: "Account segnalazione table head label C.F.",
    }),
    email: intl.formatMessage({
      id: "ACCOUNT_SEGNALAZIONE_TABLE_HEAD_EMAIL",
      defaultMessage: "Email",
      description: "Account segnalazione table head label Email",
    }),
    pec: intl.formatMessage({
      id: "ACCOUNT_SEGNALAZIONE_TABLE_HEAD_PEC",
      defaultMessage: "PEC",
      description: "Account segnalazione table head label PEC",
    }),
    sdi: intl.formatMessage({
      id: "ACCOUNT_SEGNALAZIONE_TABLE_HEAD_SDI",
      defaultMessage: "SDI",
      description: "Account segnalazione table head label SDI",
    }),
    role: intl.formatMessage({
      id: "ACCOUNT_SEGNALAZIONE_TABLE_HEAD_ROLE",
      defaultMessage: "Ruolo di",
      description: "Account segnalazione table head label ROLE",
    }),
  };
};

export const formFieldsSubscription = (
  intl: IntlFormatters,
  dataInizio?: string
): Array<FormFields> => [
  {
    id: "tipologia",
    name: "tipologia",
    label: intl.formatMessage({
      id: "SUBSCRIPTION_TIPOLOGIA",
      defaultMessage: "Tipologia",
      description: "Subscription field tipologia",
    }),
    initialValue: "",
    type: "select",
    required: false,
    gridSize: 6,
    select: true,
    SelectProps: {
      native: true,
    },
    InputLabelProps: {
      shrink: true,
    },
    children: [
      {
        id: "affitto",
        name: "affitto",
        value: "affitto",
        label: intl.formatMessage({
          id: "AFFITTO_SUBSCRIPTION_TIPOLOGIA",
          defaultMessage: "Affitto",
          description: "Subscription tipologia field option Affitto",
        }),
      },
      {
        id: "vendita",
        name: "vendita",
        value: "vendita",
        label: intl.formatMessage({
          id: "VENDITA_SUBSCRIPTION_TIPOLOGIA",
          defaultMessage: "Vendita",
          description: "Subscription tipologia field option Vendita",
        }),
      },
      {
        id: "ristrutturazione",
        name: "ristrutturazione",
        value: "ristrutturazione",
        label: intl.formatMessage({
          id: "RISTRUTTURAZIONE_SUBSCRIPTION_TIPOLOGIA",
          defaultMessage: "Ristrutturazione",
          description: "Subscription tipologia field option Ristrutturazione",
        }),
      },
    ],
  },
  {
    id: "data_inizio",
    name: "data_inizio",
    label: intl.formatMessage({
      id: "SUBSCRIPTION_DATA_INIZIO",
      defaultMessage: "Data inizio",
      description: "Subscription field Data inizio",
    }),
    required: true,
    type: "date",
    gridSize: 6,
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    InputLabelProps: {
      shrink: true,
    },
  },
  {
    id: "data_fine",
    name: "data_fine",
    label: intl.formatMessage({
      id: "SUBSCRIPTION_DATA_FINE",
      defaultMessage: "Data fine",
      description: "Subscription field Data fine",
    }),
    required: true,
    type: "date",
    gridSize: 6,
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
    InputLabelProps: {
      shrink: true,
    },
    ...(dataInizio
      ? {
          InputProps: {
            inputProps: {
              min: dataInizio,
            },
          },
        }
      : undefined),
  },
];

export const formFieldsQuadranti = (
  intl: IntlFormatters
): Array<FormFields> => [
  {
    id: "nome",
    name: "nome",
    label: intl.formatMessage({
      id: "QUADRANTE_NOME",
      defaultMessage: "Nome",
      description: "Quadrante field Nome",
    }),
    required: true,
    type: "string",
    gridSize: 12,
    initialValue: "",
    errorObject: {
      required: intl.formatMessage({
        id: "GENERAL_MANDATORY_FIELD",
        defaultMessage: "Questo campo è obbligatorio",
        description: `Form field mandatory message`,
      }),
    },
  },
];

export const dropZoneAreaTexts = (intl: IntlFormatters): DropZoneAreaProps => {
  return {
    dropzoneText: intl.formatMessage({
      id: "DROPZONE_TEXT",
      defaultMessage: "Trascina un'immaigne oppure clicca qui",
      description: `Dropzone text description`,
    }),
    dropzoneFileAddedMessage: (fileName: string): string => {
      const message = intl.formatMessage({
        id: "DROPZONE_ADDED_FILE_MESSAGE",
        defaultMessage: `File ${fileName} aggiunto con successo`,
        description: `Dropzone alert message when file added`,
      });
      return message;
    },
    dropzoneFileRemovedMessage: (fileName: string): string => {
      const message = intl.formatMessage({
        id: "DROPZONE_REMOVED_FILE_MESSAGE",
        defaultMessage: `File ${fileName} rimosso`,
        description: `Dropzone alert message when file removed`,
      });
      return message;
    },
  };
};

export const algoliaFilters = (intl: IntlFormatters): any => {
  return {
    immobile: {
      destinazione: intl.formatMessage({
        id: "ALGOLIA_FIELD_IMMOBILE_DESTINAZIONE",
        defaultMessage: "Destinazione",
        description: `Algolia destinazione field`,
      }),
      citta: intl.formatMessage({
        id: "ALGOLIA_FIELD_IMMOBILE_CITTA",
        defaultMessage: "Città",
        description: `Algolia città field`,
      }),
      "quadranti.nome": intl.formatMessage({
        id: "ALGOLIA_FIELD_IMMOBILE_QUADRANTI_NOME",
        defaultMessage: "Quadrante",
        description: `Algolia nome quadrante field`,
      }),
      "proprietari.nome": intl.formatMessage({
        id: "ALGOLIA_FIELD_IMMOBILE_PROPRIETARI_NOME",
        defaultMessage: "Nome",
        description: `Algolia nome proprietario field`,
      }),
      "proprietari.cognome": intl.formatMessage({
        id: "ALGOLIA_FIELD_IMMOBILE_PROPRIETARI_COGNOME",
        defaultMessage: "Cognome",
        description: `Algolia Cognome proprietario field`,
      }),
    },
    segnalazione: {
      tipo_immobile: intl.formatMessage({
        id: "ALGOLIA_FIELD_SEGNALAZIONE_TIPOLOGIA_IMMOBILE",
        defaultMessage: "Tipologia Immobile",
        description: `Algolia tipologia immobile field`,
      }),
      stato: intl.formatMessage({
        id: "ALGOLIA_FIELD_SEGNALAZIONE_STATO",
        defaultMessage: "Stato",
        description: `Algolia stato segnalazione field`,
      }),
      "ubicazione.citta": intl.formatMessage({
        id: "ALGOLIA_FIELD_SEGNALAZIONE_CITTA",
        defaultMessage: "Città",
        description: `Algolia citta segnalazione field`,
      }),
      "proprietario.nome": intl.formatMessage({
        id: "ALGOLIA_FIELD_SEGNALAZIONE_PROPRIETARIO_NOME",
        defaultMessage: "Nome",
        description: `Algolia nome proprietario field`,
      }),
      "proprietario.cognome": intl.formatMessage({
        id: "ALGOLIA_FIELD_SEGNALAZIONE_PROPRIETARIO_COGNOME",
        defaultMessage: "Cognome",
        description: `Algolia Cognome proprietario field`,
      }),
      "user.nome": intl.formatMessage({
        id: "ALGOLIA_FIELD_SEGNALAZIONE_SEGNALATORE_NOME",
        defaultMessage: "Nome",
        description: `Algolia nome segnalatore field`,
      }),
      "user.cognome": intl.formatMessage({
        id: "ALGOLIA_FIELD_SEGNALAZIONE_SEGNALATORE_COGNOME",
        defaultMessage: "Cognome",
        description: `Algolia Cognome segnalatore field`,
      }),
    },
    viewAllOptions: intl.formatMessage({
      id: "ALGOLIA_SELECT_ALL_OPTIONS",
      defaultMessage: "Vedi tutte le opzioni",
      description: `Algolia view all options`,
    }),
  };
};
