import * as React from "react";
import { useHistory } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import useLogin from "../helpers/useLogin";
import useAuth from "../helpers/useAuth";
import useApi from "../helpers/useApi";
import usePermissions from "../helpers/usePermissions";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import { Power } from "react-feather";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserMenu() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const auth = useLogin();
  const history = useHistory();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const signOut = () => {
    auth.signOut();
    history.push("/auth/sign-in");
  };

  const profile = () => {
    history.push("/me");
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={profile}>Profile</MenuItem>
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

type HeaderProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Header: React.FC<HeaderProps> = ({ onDrawerToggle }) => {
  const [currentEnvironment, setCurrentEnvironment] = React.useState<string>(
    ""
  );
  const auth = useAuth();
  const api = useApi();
  const permissions = usePermissions();

  const myEnvironments = async () => {
    const envs = await api.me.myEnvironments();
    if (envs && envs.length) {
      // always the default environment (system) will exists
      envs.forEach((env) => {
        if (env.envId === auth.environment) {
          setCurrentEnvironment(env.envName);
        }
      });
    }
  };

  React.useEffect(() => {
    if (auth.isLogged) {
      myEnvironments();
    }

    // eslint-disable-next-line
  }, [auth.isLogged, auth.environment]);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              {!permissions.isAdmin &&
              currentEnvironment.toLocaleLowerCase() === "system" ? (
                <FormattedMessage
                  id="SYSTEM_ENVIRONMENT_NAME"
                  defaultMessage="Segnalatore"
                />
              ) : (
                currentEnvironment
              )}
            </Grid>
            <Grid item>
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default connect()(withTheme(Header));
