import React from "react";

import async from "../components/Async";

import {
  Monitor,
  Users,
  Home,
  User,
  Box,
  CreditCard,
  Minimize,
  Trello,
} from "react-feather";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Profile = async(() => import("../pages/auth/Profile"));
const Invites = async(() => import("../pages/auth/Invites"));

// Pages components
const ResultsList = async(() => import("../pages/results/Results"));
const DashboardSegnalatore = async(
  () => import("../pages/dashboard/Segnalatore")
);
const DashboardAdmin = async(() => import("../pages/dashboard/Admin"));
const DashboardRealEstate = async(() => import("../pages/dashboard/RealEstate"));
const Segnalazione = async(() => import("../pages/segnalazione/Segnalazione"));
const Immobile = async(() => import("../pages/Immobile/Immobile"));
const ListaImmobile = async(() => import("../pages/Immobile/ListaImmobile"));
const ListaSegnalazioni = async(
  () => import("../pages/segnalazione/ListaSegnalazioni")
);
const ListaContatti = async(() => import("../pages/contatti/ListaContatti"));
const ListQuadranti = async(() => import("../pages/quadranti/ListQuadranti"));
const ListSubscriptions = async(
  () => import("../pages/subscription/ListSubscriptions")
);
const Grazie = async(() => import("../pages/segnalazione/Grazie"));
const AccountFatturazione = async(
  () => import("../pages/accountFatturazione/AccountFatturazione")
);
const UsersList = async(() => import("../pages/user/Users"));

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
  ],
  component: null,
};

const resultsListRoutes = {
  id: "ResultsList",
  path: "/",
  header: "ResultsList",
  icon: <Monitor />,
  component: ResultsList,
  children: null,
};

const dashboardSegnalatoreRoutes = {
  id: "Dashboard",
  path: "/",
  component: DashboardSegnalatore,
  icon: <Trello />,
  children: null,
};
const dashboardAdminRoutes = {
  id: "Dashboard",
  path: "/",
  component: DashboardAdmin,
  icon: <Trello />,
  children: null,
};
const dashboardRealEstate = {
  id: "Dashboard",
  path: "/",
  component: DashboardRealEstate,
  icon: <Trello />,
  children: null,
};

const immobileRoutes = {
  id: "Immobile",
  path: "/immobile",
  icon: <Home />,
  component: Immobile,
  children: [
    {
      path: "/immobile/creazione",
      name: "Creazione",
      component: Immobile,
    },
    {
      path: "/immobile/lista",
      name: "Lista",
      component: ListaImmobile,
    },
  ],
};
const immobileRoutesRealEstate = {
  id: "Immobile",
  path: "/immobile",
  icon: <Home />,
  component: Immobile,
  children: [
    {
      path: "/immobile/lista",
      name: "Lista",
      component: ListaImmobile,
    },
  ],
};

const segnalazioneRoutes = {
  id: "Segnalazione",
  path: "/segnalazione",
  icon: <Monitor />,
  component: Segnalazione,
  children: [
    {
      path: "/segnalazione/segnalazione",
      name: "Creazione",
      component: Segnalazione,
    },
    {
      path: "/segnalazione/lista",
      name: "Lista",
      component: ListaSegnalazioni,
    },
  ],
};
const contattiRoutes = {
  id: "Contatti",
  path: "/contatti",
  icon: <User />,
  name: "Contatti",
  component: ListaContatti,
  children: null,
};
const accountFatturazioneRoutes = {
  id: "Account di Fatturazione",
  path: "/acount-fatturazione",
  icon: <CreditCard />,
  name: "Account di Fatturazione",
  component: AccountFatturazione,
  children: null,
};
const quadrantiRoutes = {
  id: "Quadranti",
  path: "/quadranti",
  icon: <Box />,
  name: "Quadranti",
  component: ListQuadranti,
  children: null,
};
const SegnalazioneGrazie = {
  id: "SegnalazioneGrazie",
  path: "/segnalazione/grazie",
  name: "Grazie",
  component: Grazie,
  children: null,
};
const SegnalazioneDetails = {
  id: "SegnalazioneDetails",
  path: "/segnalazione/lista/:id",
  name: "Details",
  component: ListaSegnalazioni,
  children: null,
};
const profileRoutes = {
  id: "User",
  path: "/me",
  icon: <Users />,
  component: Profile,
  children: null,
};

const invitesRoutes = {
  id: "Inviti",
  path: "/invites",
  icon: <Users />,
  component: Invites,
  children: null,
};

const usersRoutes = {
  id: "Utenti",
  path: "/users",
  icon: <Users />,
  component: UsersList,
  children: null,
};

const subscriptionsRoutes = {
  id: "Abbonamenti",
  path: "/subscriptions",
  icon: <Minimize />,
  name: "Abbonamenti",
  component: ListSubscriptions,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutesUser = [
  dashboardSegnalatoreRoutes,
  segnalazioneRoutes,
  profileRoutes,
  SegnalazioneGrazie,
  SegnalazioneDetails,
  invitesRoutes,
  accountFatturazioneRoutes,
];

export const dashboardLayoutRoutesRealEstate = [
  dashboardRealEstate,
  segnalazioneRoutes,
  profileRoutes,
  immobileRoutes,
  SegnalazioneGrazie,
  SegnalazioneDetails,
  invitesRoutes,
  accountFatturazioneRoutes,
  subscriptionsRoutes,
];

export const dashboardLayoutRoutesAdmin = [
  dashboardAdminRoutes,
  resultsListRoutes,
  segnalazioneRoutes,
  profileRoutes,
  usersRoutes,
  immobileRoutes,
  SegnalazioneGrazie,
  SegnalazioneDetails,
  contattiRoutes,
  quadrantiRoutes,
  invitesRoutes,
  accountFatturazioneRoutes,
  subscriptionsRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar for Tipshere User
export const sidebarRoutesRealEstate = [
  dashboardRealEstate,
  segnalazioneRoutes,
  immobileRoutesRealEstate,
  accountFatturazioneRoutes,
  subscriptionsRoutes,
];
// Routes visible in the sidebar for Tipshere User
export const sidebarRoutesAdmin = [
  dashboardAdminRoutes,
  segnalazioneRoutes,
  immobileRoutes,
  contattiRoutes,
  quadrantiRoutes,
  accountFatturazioneRoutes,
  subscriptionsRoutes,
  usersRoutes,
];

// Routes visible in the sidebar for NON Tipshere User
export const sidebarRoutes = [
  dashboardSegnalatoreRoutes,
  segnalazioneRoutes,
  accountFatturazioneRoutes,
];
